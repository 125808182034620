import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import IconButton from 'Common/Components/IconButton';
import { Edit } from 'Screens/Contextualization/Manage/components/CustomActionBar/styled';
import { selectHostsSelected } from 'store/Host/selectors';
import { redirectToHostDetail } from 'store/Contextualization/AssetDetail/actions';
import { selectAssetRow, unSelectAll } from 'store/Host/actions';
import ModalWarning from 'Common/Components/ModalWarning';

const EditHostButton = ({ hasHosts }) => {
  const hostsSelected = useSelector(selectHostsSelected);
  const hostId = get(hostsSelected, '[0].id', 0);
  const host = get(hostsSelected, '[0]', null);
  const [showWarning, setShowWarning] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'asset' });

  const handleClick = (e) => {
    if (hasHosts) {
      if (hostsSelected.length > 0) showHostDetail(e);
      else setShowWarning(true);
    }
  };

  const showHostDetail = (e) => {
    dispatch(unSelectAll());
    dispatch(redirectToHostDetail(hostId));
    dispatch(selectAssetRow(e, host));
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <Edit /> } title="Edit" onClick={ handleClick } disabled={ !hasHosts } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Edit" description={ message } /> }
    </>
  );
};

export default EditHostButton;
