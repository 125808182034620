import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import IconButton from 'Common/Components/IconButton';
import { RefreshIcon } from './styled';
import { getAssetServices } from 'store/Contextualization/AssetDetail/actions';
import { selectCurrentHost } from 'store/Host/selectors';

const RefreshButton = () => {
  const dispatch = useDispatch();
  const currentAsset = useSelector(selectCurrentHost);
  const currentAssetId = get(currentAsset, '_id', 0);
  const handleClick = () => dispatch(getAssetServices(currentAssetId));

  return (
    <IconButton icon={ <RefreshIcon /> } title="Refresh" onClick={ handleClick } />
  );
};

export default RefreshButton;
