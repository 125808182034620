import { addFilter, removeFilter, setSaveEnabled } from 'store/Filters/actions';
import { isFilteringBy } from 'store/Filters/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { newGetVulns } from 'store/Manage/filterActions';
import { getData } from 'store/Host/actions';
import { getVulnsByAsset } from 'store/Contextualization/AssetDetail/actions';

const useFilterToggle = (filter, entityName) => {
  const dispatch = useDispatch();
  const isFilterActive = useSelector((state) => isFilteringBy(state, entityName, filter));

  const toggleFilter = () => {
    if (isFilterActive) dispatch(removeFilter(entityName, filter));
    else dispatch(addFilter(entityName, filter));
    if (entityName === 'vulns') dispatch(newGetVulns());
    else if (entityName === 'assets') dispatch(getData());
    else if (entityName === 'vulnsAssets') dispatch(getVulnsByAsset());
    dispatch(setSaveEnabled(true));
  };

  return [isFilterActive, toggleFilter];
};

export default useFilterToggle;
