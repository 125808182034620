import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as FilterSVG } from 'Images/shape.svg';

export const Wrapper = styled.div`
  
`;
Wrapper.displayName = 'Wrapper';

export const FilterIcon = styled(FilterSVG)`
  & > path {
    fill: #1c4566;
  }
`;
FilterIcon.displayName = 'FilterIcon';

export const Dropdown = styled.div`
  position: absolute;
  width: 200px;
  box-shadow: 2px 2px 10px 0 #d5dee6;
  background-color: ${colors.white};
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0.25rem;
  z-index: 3;
`;
Dropdown.displayName = 'Dropdown';
