import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Wrapper, ModalWrapper, Title, Message, ButtonsWrapper, Cancel, Done
} from './styled';
import useKeyPress from 'Hooks/useKeyPress';

const ConfirmNavigationModal = ({
  title, message, onCancel, onDone, cancel, done
}) => {
  useKeyPress(() => {
    if (onDone) onDone();
  }, ['Enter']);

  useKeyPress(() => {
    if (onCancel) onCancel();
  }, ['Escape']);
  return (
    <Wrapper>
      <ModalWrapper>
        <Title>{ title }</Title>
        <Message>{ message }</Message>
        <ButtonsWrapper>
          <Cancel onClick={ onCancel }>{ cancel }</Cancel>
          <Done onClick={ onDone }>{ done }</Done>
        </ButtonsWrapper>
      </ModalWrapper>
    </Wrapper>
  );
};

ConfirmNavigationModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  cancel: PropTypes.string.isRequired,
  done: PropTypes.string.isRequired
};

export default ConfirmNavigationModal;
