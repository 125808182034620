import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import IconButton from 'Common/Components/IconButton';
import { Edit } from 'Screens/Contextualization/Manage/components/CustomActionBar/styled';
import { selectSelected } from 'store/Contextualization/AssetDetail/selectors';
import { redirectToServiceDetail, selectServiceRow, unSelectAll } from 'store/Contextualization/AssetDetail/actions';
import ModalWarning from 'Common/Components/ModalWarning';

const EditServiceButton = ({ hasServices }) => {
  const [showWarning, setShowWarning] = useState(false);
  const servicesSelected = useSelector((state) => selectSelected('services', state));
  const serviceId = get(servicesSelected, '[0].id', 0);
  const service = get(servicesSelected, '[0]', null);
  const dispatch = useDispatch();
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'services' });

  const showServiceDetail = (e) => {
    dispatch(unSelectAll('services'));
    dispatch(redirectToServiceDetail(serviceId));
    dispatch(selectServiceRow(e, service));
  };

  const handleClick = (e) => {
    if (hasServices) {
      if (servicesSelected.length > 0) showServiceDetail(e);
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <Edit /> } title="Edit" onClick={ handleClick } disabled={ !hasServices } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Edit" description={ message } /> }
    </>
  );
};

export default EditServiceButton;
