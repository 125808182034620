import get from 'lodash/get';

export const selectCurrentWorkspace = (state) => get(state, 'faraday.workspaceSelected', '');
export const selectShowWorkspaceValidator = (state) => get(state, 'faraday.showWorkspaceValidator', false);
export const selectEmail = (state) => get(state, 'faraday.email', '');
export const selectUrlReturn = (state) => get(state, 'faraday.urlReturn', ''); // url que determina a donde ir luego de loguearse.
export const selectIsAdmin = (state) => get(state, 'faraday.role', '') === 'admin';
export const selectUserName = (state) => get(state, 'faraday.userName', '');
export const selectApiVersion = (state) => get(state, 'faraday.apiVersion', '');
export const selectWsPort = (state) => get(state, 'faraday.wsPort', 0);
export const selectFaradayLoading = (state) => get(state, 'workspace.isFetching', 0);
export const selectReadOnly = (state) => get(state, 'faraday.readonly', false);
export const selectAllVulnsCount = (state) => get(state, 'faraday.vulnsCount.total', 0);
export const selectConfirmedVulnsCount = (state) => get(state, 'faraday.vulnsCount.confirmed', 0);
export const selectUnconfirmedVulnsCount = (state) => get(state, 'faraday.vulnsCount.unconfirmed', 0);
export const selectAllHostsCount = (state) => get(state, 'faraday.hostsCount', 0);
