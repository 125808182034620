import styled from 'styled-components';
import StringField from 'Common/Components/StringField';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    overflow: auto;
`;
Wrapper.displayName = 'Wrapper';

export const Row = styled.div`
    display: flex;
`;
Row.displayName = 'Row';

export const NewColumn = styled.div`
width: 406px;
`;
Row.displayName = 'Row';

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
`;
Column.displayName = 'Column';

export const ExternalId = styled(StringField)`

`;

ExternalId.displayName = 'ExternalId';

export const RightColumn = styled(Column)`
    & > :first-child{
        margin-bottom: 10px;
    }
`;
RightColumn.displayName = 'RightColumn';

export const PolicyColumn = styled(Column)`
    margin-right: 20px;
    width: 48%;
`;
PolicyColumn.displayName = 'PolicyColumn';

export const AditionalText = styled.div`
    width: 220px;
    height: 36px;    
    font-size: 13.5px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.67;
    letter-spacing: 0.1px;
    color: #afb1bc;
    margin-left: 20px;
`;
AditionalText.displayName = 'AditionalText';

export const Gap = styled.div`
  height: 20px;
`;

export const Title = styled.div`
    font-size: 12.5px;
    font-weight: 500;
    line-height: 2.08;
    color: ${colors.darkBlueGrey};
    font-weight: 600;
    margin-bottom: 12px;
    ${({ required }) => required && `
        position: relative;
        &:after {
        content: '*';
        color: ${colors.redPink};
        position: absolute;
        top: 3px;
        margin-left: 3px;
        }
    `}  
`;
Title.displayName = 'Title';

export const Box = styled.div`
    width: 398px;
`;
Box.displayName = 'Box';
