import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import CustomReactTable from 'Common/Components/Contextualization/CustomReactTable/styled';
import { selectServices, selectIsFetching, selectSelected } from 'store/Contextualization/AssetDetail/selectors';
import getColumns from './components/Columns';
import { selectAssetServicesFields } from 'store/Preferences/assetServices/selectors';
import { getAssetServices } from 'store/Contextualization/AssetDetail/actions';

const ServicesTable = ({ params }) => {
  const dispatch = useDispatch();
  const services = useSelector(selectServices);
  const isFetching = useSelector((state) => selectIsFetching('services', state));
  const fields = useSelector(selectAssetServicesFields);
  const columns = getColumns(fields, isFetching);
  const servicesSelected = useSelector((state) => selectSelected('services', state));
  const assetId = get(params, 'id', 0);
  const mockData = [...Array(15).keys()];

  const getTrProps = (rowInfo, servicesSelected) => {
    const selected = servicesSelected.find((vuln) => vuln._id === rowInfo.original._id);
    if (rowInfo && rowInfo.row) {
      return {
        style: {
          background: selected ? '#edf2f7' : 'transparent'
        }
      };
    }
    return {};
  };

  const getThProps = (column, fields) => {
    const defaultMin = fields[column.id] ? fields[column.id].defaultWidth : 0;
    const maxWidth = fields[column.id] ? fields[column.id].maxWidth : 0;
    const response = { minWidth: defaultMin, maxWidth };
    return {
      style: defaultMin ? response : {}
    };
  };

  const fetchData = () => !isFetching && dispatch(getAssetServices(assetId));

  return (
    <CustomReactTable
      data={ isFetching ? mockData : services }
      columns={ columns }
      minRows={ 0 }
      manual
      onFetchData={ fetchData }
      showPagination={ false }
      getTrProps={ (_, rowInfo) => getTrProps(rowInfo, servicesSelected) }
      getTheadThProps={ (_, __, column) => getThProps(column, fields) }
      noDataText="Currently, there are no records to display"
    />
  );
};
export default ServicesTable;
