import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import MarkdownEditor from 'Common/Components/v2/MarkdownEditor';
import ExpandableWrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';
import { selectHostDetailDescription, selectAssetDetailId, selectReadonly } from 'store/Contextualization/AssetDetail/selectors';
import { selectPermissions } from 'store/Host/selectors';
import { updateHost } from 'store/Host/actions';

const ID = 'description';

const Description = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const hostId = useSelector(selectAssetDetailId);
  const description = useSelector(selectHostDetailDescription);
  const readOnly = useSelector(selectReadonly);
  const permissions = useSelector(selectPermissions);
  const disabled = readOnly || !permissions.update.allowed;
  const isExpanded = useExpandable('general', ID, description);

  return (
    <ExpandableWrapper defaultValue={ isExpanded } title={ intl.formatMessage({ id: 'manage.detail.tab.general.description' }) } tab="general" id={ ID }>
      <MarkdownEditor
        value={ description }
        onBlur={ (value) => dispatch(updateHost(hostId, { description: value })) }
        disabled={ disabled }
        minHeight={ 49 }
      />
    </ExpandableWrapper>
  );
};

export default Description;
