import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allServicesAreSelected, someServiceIsSelected } from 'store/Services/selectors';
import { selectAllService } from 'store/Services/actions';
import Checkbox from 'Common/Components/Checkbox';

export const CheckHeader = () => { // eslint-disable-line import/prefer-default-export
  const allServiceSelected = useSelector(allServicesAreSelected);
  const someServiceSelected = useSelector(someServiceIsSelected);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(selectAllService());
  };

  return (
    <Checkbox theme="grey" onChange={ handleClick } state={ allServiceSelected } partialChecked={ someServiceSelected && !allServiceSelected } />
  );
};
