import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectHostDetail, selectAssetDetailId } from 'store/Contextualization/AssetDetail/selectors';
import debounce from 'lodash/debounce';
import { updateHost } from 'store/Host/actions';
import { Wrapper, InputTitle, IconHost } from './styled';
import { selectReadOnly } from 'store/Faraday/selectors';

const Title = () => {
  const dispatch = useDispatch();
  const hostDetail = useSelector(selectHostDetail);
  const hostId = useSelector(selectAssetDetailId);
  const [hostIp, setHostIp] = useState(hostDetail.ip);
  const readOnly = useSelector(selectReadOnly);
  const disabled = readOnly;

  useEffect(() => {
    setHostIp(hostDetail.ip);
  }, [hostDetail]);

  const updateIp = useRef(debounce((ip) => {
    if (ip) {
      dispatch(updateHost(hostId, { ip }));
    }
  }, 1000));

  const handleChange = (e) => {
    const ip = e.target.value;
    setHostIp(ip);
    updateIp.current(ip);
  };

  return (
    <Wrapper>
      <IconHost />
      <InputTitle value={ hostIp } onChange={ handleChange } disabled={ disabled } />
    </Wrapper>
  );
};

export default Title;
