/* eslint-disable import/prefer-default-export */
import React from 'react';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useDispatch } from 'react-redux';
import { saveTemplateFromVuln } from 'store/Manage/actions';
import { useIntl } from 'react-intl';
import { SaveTemplateIcon } from './styled';
import { showContextMenu } from 'store/Contextualization/AssetDetail/actions';

export const SaveAsTemplate = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <MenuItem
      title={ intl.formatMessage({ id: 'manage.cm.saveAsTemplate' }) }
      icon={ <SaveTemplateIcon /> }
      onClick={ () => {
        dispatch(saveTemplateFromVuln());
        dispatch(showContextMenu(false));
      } }
    />
  );
};
