import React from 'react';
import get from 'lodash/get';
// import { selectCurrentWorkspace } from 'store/Faraday/selectors';

import { ReactComponent as Dashboard } from 'Images/manage.svg';
import { ReactComponent as Automation } from 'Images/automation.svg';
import { ReactComponent as Planner } from 'Images/tasks_icon.svg';
import { ReactComponent as Reports } from 'Images/reports.svg';
import { ReactComponent as Analytics } from 'Images/analytics.svg';
import { ReactComponent as Users } from 'Images/icon_users.svg';
import { ReactComponent as KnowledgeBase } from 'Images/vuln-kb.svg';

import FFAnalytics from 'Images/ff_analytics.png';
import FFPlanner from 'Images/ff_planner.png';
import FFReports from 'Images/ff_reporting.png';
import FFScheduler from 'Images/ff_scheduler.png';
import FFPipelines from 'Images/ff_pipelines.png';
import FFUsers from 'Images/ff_users.png';
import { selectLastUrlVisitedInsideWs, selectWorkspaceSelected } from 'store/Global/selectors';
import { selectActiveWorkspaces } from 'store/Workspace/selectors';

export const selectIsOpen = (state) => get(state, 'sideBar.isOpen', false);
export const selectPagesData = (state) => {
  const workspacesList = selectActiveWorkspaces(state);
  const workspaceSelected = selectWorkspaceSelected(state);
  const isWsActive = workspacesList.map((ws) => ws.name).includes(workspaceSelected);
  const vulnerabilityManagementUrl = selectLastUrlVisitedInsideWs(state);

  return [
    {
      name: 'Management',
      icon: <Dashboard />,
      subMenu: [
        {
          name: 'Management',
          url: isWsActive ? vulnerabilityManagementUrl : '/workspaces',
          enabledByRole: true,
          enabledByFeature: true
        }
      ]
    },
    {
      name: 'Knowledge Base',
      icon: <KnowledgeBase />,
      subMenu: [
        {
          name: 'Knowledge Base',
          url: '/knowledge_base',
          enabledByRole: true,
          enabledByFeature: true
        }
      ]
    },
    {
      name: 'Analytics',
      icon: <Analytics />,
      subMenu: [
        {
          name: 'Analytics',
          url: null,
          enabledByRole: true,
          enabledByFeature: false,
          FF: {
            image: FFAnalytics,
            description: 'upgradeLicense.sidebar.analytics.description'
          }
        }
      ]
    },
    {
      name: 'Planner',
      icon: <Planner />,
      subMenu: [
        {
          name: 'Planner',
          url: null,
          enabledByRole: true,
          enabledByFeature: false,
          FF: {
            image: FFPlanner,
            description: 'upgradeLicense.sidebar.planner.description'
          }
        }
      ]
    },
    {
      name: 'Reports',
      icon: <Reports />,
      subMenu: [
        {
          name: 'Reports',
          url: null,
          enabledByRole: true,
          enabledByFeature: false,
          FF: {
            image: FFReports,
            description: 'upgradeLicense.sidebar.reporting.description'
          }
        }
      ]
    },
    {
      name: 'Automation',
      icon: <Automation />,
      subMenu: [
        {
          name: 'Agents',
          url: '/automation/agents',
          enabledByRole: true,
          enabledByFeature: true
        },
        {
          name: 'Scheduler',
          url: null,
          enabledByRole: true,
          enabledByFeature: false,
          FF: {
            image: FFScheduler,
            description: 'upgradeLicense.sidebar.scheduler.description'
          }
        },
        {
          name: 'Pipelines',
          url: null,
          enabledByRole: true,
          enabledByFeature: false,
          FF: {
            image: FFPipelines,
            description: 'upgradeLicense.sidebar.pipelines.description'
          }
        },
        {
          name: 'Jobs',
          url: null,
          enabledByRole: true,
          enabledByFeature: false,
          FF: {
            image: FFPipelines,
            description: 'upgradeLicense.sidebar.pipelines.description'
          }
        }
      ]
    },
    {
      name: 'Users',
      icon: <Users />,
      subMenu: [
        {
          name: 'Users',
          url: null,
          enabledByRole: true,
          enabledByFeature: false,
          FF: {
            image: FFUsers,
            description: 'upgradeLicense.sidebar.users.description'
          }
        }
      ]
    }
  ];
};
