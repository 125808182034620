/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InputFilter from 'Common/Components/InputFilter';
import {
  getTemplates, setField, addPolicy, removePolicy,
  selectCheckbox, addReference, removeReference,
  addCVE, removeCVE, getHosts, addAsset, removeAsset
} from 'Screens/Contextualization/ManageEditCreate/actions/Actions';
import {
  selectTemplates,
  selectManageEditCreate,
  selectNameField,
  selectAllHosts,
  selectTargetsHosts
} from 'store/ManageEditCreate/selectors';
import SeverityDropdown from 'Common/Components/SeverityDropdown';
import EORDropdown from 'Common/Components/EORDropdown';
import CustomList from 'Common/Components/CustomList';
import Impacts from 'Common/Components/Impacts';
import MarkdownInput from '../MarkdownInput';
import {
  Wrapper, Row, ExternalId, NewColumn, Gap, Title, Box
} from './styled';
import debounce from 'lodash/debounce';
import CVE from './InputCVE';
import InputSearch from './InputSearch';
import { selectCurrentHost } from 'store/Host/selectors';

const General = ({ assets, setAssets }) => {
  const templates = useSelector(selectTemplates);
  const manageEditCreate = useSelector(selectManageEditCreate);
  const name = useSelector(selectNameField);
  const hosts = useSelector(selectAllHosts);
  const addedHosts = useSelector(selectTargetsHosts);
  const assetForWorking = useSelector(selectCurrentHost);
  const createFromAsset = Object.keys(assetForWorking).length > 0;
  const dispatch = useDispatch();

  const handleChange = (field, value) => {
    dispatch(setField(field, value));
  };

  const debouncedSetField = debounce(handleChange, 250);

  const applyTemplate = (template) => {
    dispatch(setField('name', template.name));
    dispatch(setField('description', template.description));
    dispatch(setField('resolution', template.resolution));
    dispatch(setField('severity', template.exploitation));
    dispatch(setField('policies', template.policyviolations));
    dispatch(setField('references', template.refs));
    dispatch(setField('externalId', template.external_id));
    dispatch(setField('accountability', template.impact.accountability));
    dispatch(setField('availability', template.impact.availability));
    dispatch(setField('integrity', template.impact.integrity));
    dispatch(setField('confidentiality', template.impact.confidentiality));
    dispatch(setField('easeOfResolution', template.easeofresolution));
    dispatch(setField('data', template.data));
    dispatch(setField('customFields', template.customfields));
    dispatch(setField('CVE', template.cve));
  };

  const setTarget = (value) => {
    const temp = addedHosts;
    const found = temp.find((item) => item.id === value.id);
    if (!found) {
      dispatch(addAsset(value));
      setAssets([...assets, value]);
    }
  };

  const onRemoveTarget = (value) => {
    const temp = addedHosts;
    const index = temp.findIndex((item) => item.id === value.id);
    if (index > -1) {
      temp.splice(index, 1);
      setAssets(temp);
      dispatch(removeAsset(value));
    }
  };
  const impacts = {
    accountability: manageEditCreate.accountability,
    availability: manageEditCreate.availability,
    confidentiality: manageEditCreate.confidentiality,
    integrity: manageEditCreate.integrity
  };

  return (
    <Wrapper>
      <NewColumn>
        { !createFromAsset &&
          <>
            <InputSearch
              getObjects={ () => dispatch(getHosts()) }
              onSelect={ setTarget }
              data={ hosts }
              placeholder="Add a Asset"
              id="vuln-add-assets"
              title="Asset(s)"
              required
              addedItems={ addedHosts }
              removeItem={ onRemoveTarget }
            />
            <Gap />
          </>
        }
        <Title required>Vulnerability Name</Title>
        <InputFilter
          getObjects={ () => dispatch(getTemplates()) }
          data={ templates }
          width="405px"
          height="38px"
          placeholder="Search or give a name to your Vulnerability"
          onSelect={ applyTemplate }
          onChange={ debouncedSetField }
          value={ name }
        />
        <Gap />
        <Title>CVE</Title>
        <CVE
          listData={ manageEditCreate.CVE }
          onAdd= { (val) => dispatch(addCVE(val)) }
          onRemove={ (val) => dispatch(removeCVE(val)) }
        />
        <Gap />
        <MarkdownInput
          title="Description"
          value={ manageEditCreate.description }
          onBlur={ (value) => debouncedSetField('description', value) }
          isRequired
          minHeight={ 130 }
        />
        <CustomList
          key="CL_POLICY_VIOLATIONS"
          title="Policy Violations"
          placeholder="Add a Policy Violation"
          addItem={ (val) => dispatch(addPolicy(val)) }
          removeItem={ (index) => dispatch(removePolicy(index)) }
          listData={ manageEditCreate.policies || [] }
          responsive
        />
        <Gap />
        <Box>
          <CustomList
            key="REFERENCES"
            title="References"
            placeholder="Add Reference"
            addItem={ (val) => dispatch(addReference(val)) }
            removeItem={ (index) => dispatch(removeReference(index)) }
            listData={ manageEditCreate.references || [] }
            responsive
          />
        </Box>
      </NewColumn>
      <NewColumn>
      { !createFromAsset && <><Gap /><Gap /></> }
        <Row>
          <SeverityDropdown updateValue={ (fieldName, value) => dispatch(setField(fieldName, value)) } defaultValue={ manageEditCreate.severity || '' } context />
          <EORDropdown updateValue={ (fieldName, value) => dispatch(setField(fieldName, value)) } defaultValue={ manageEditCreate.easeOfResolution || '' } />
        </Row>
        <Gap />
        <ExternalId
          width="405px"
          height="38px"
          title="External ID"
          name="external_id"
          defaultValue={ manageEditCreate.externalId || '' }
          onChange={ (value) => debouncedSetField('externalId', value) }
        />
        <Gap />
        <Box>
          <MarkdownInput
            title="Resolution"
            value={ manageEditCreate.resolution }
            onBlur={ (value) => debouncedSetField('resolution', value) }
            minHeight={ 222 }
          />
        </Box>
        <Impacts selectCheckbox={ (field) => dispatch(selectCheckbox(field)) } impacts={ impacts } />
      </NewColumn>
    </Wrapper>
  );
};

export default withRouter(General);
