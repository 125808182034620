import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { redirectToHost } from 'store/Contextualization/AssetDetail/actions';
import { selectAssetDetailId, selectHostDetailOwned } from 'store/Contextualization/AssetDetail/selectors';
import Title from './Title';
import CopyLink from './CopyLink';
import CopyApiLink from './CopyApiLink';
import { Wrapper, CloseIconWrapper, CloseIcon, Owned } from './styled';
import { updateHost } from 'store/Host/actions';
import { selectReadOnly } from 'store/Faraday/selectors';

const Header = () => {
  const dispatch = useDispatch();
  const owned = useSelector(selectHostDetailOwned);
  const hostId = useSelector(selectAssetDetailId);
  const intl = useIntl();
  const ownedText = owned ? intl.formatMessage({ id: 'host.hostDetail.owned' }) : intl.formatMessage({ id: 'host.hostDetail.notOwned' });
  const readOnly = useSelector(selectReadOnly);
  const canUpdate = !readOnly;

  const updateOwned = () => {
    if (canUpdate) dispatch(updateHost(hostId, { owned: !owned }));
  };

  return (
    <Wrapper>
      <Title />
      <Owned owned={ owned } onClick={ updateOwned } disabled={ readOnly }>{ ownedText }</Owned>
      <CopyLink />
      <CopyApiLink />
      <CloseIconWrapper onClick={ () => dispatch(redirectToHost()) }>
        <CloseIcon />
      </CloseIconWrapper>
    </Wrapper>
  );
};

export default Header;
