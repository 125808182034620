import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllHostsCount, selectReadOnly } from 'store/Faraday/selectors';
import { RiFileEditLine as EditLine, RiFileDownloadLine as DownloadLine } from 'react-icons/ri';
import ManualCreationModal from 'Screens/Contextualization/ManageEditCreate/components/ManualCreationModal';
import ImportFileCreationModal from 'Screens/Contextualization/ManageEditCreate/components/ImportFileCreationModal';
import useClickOutside from 'Hooks/useClickOutside';
import {
  DropdownText, DropdownItem, Wrapper, DropdownWrapper, PlusIcon, Dropdown
} from './styled';
import { openModal } from 'store/modals/actions';
import { MODAL_CANT_CREATE } from 'store/modals/modals';

const CreateActions = () => {
  const [showModalCreation, setShowModalCreation] = useState(false);
  const [showModalImport, setShowModalImport] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  const readOnly = useSelector(selectReadOnly);
  const canUpdate = !readOnly;
  const assetsCount = useSelector(selectAllHostsCount);
  const noAssets = assetsCount === 0;

  const dropdownRef = useRef();
  useClickOutside(dropdownRef, () => setShowDropdown(false));

  const handleShow = () => {
    if (noAssets) dispatch(openModal(MODAL_CANT_CREATE));
    else setShowModalCreation(true);
    setShowDropdown(false);
  };
  const handleClose = () => setShowModalCreation(false);
  const handleShowImportFromFile = () => {
    setShowModalImport(true);
    setShowDropdown(false);
  };
  const handleCloseImportFromFile = () => setShowModalImport(false);

  const handleDropdown = () => {
    if (canUpdate) setShowDropdown(!showDropdown);
  };

  return (
    <Wrapper ref={ dropdownRef }>
      <DropdownWrapper onClick={ handleDropdown } data-tour="Add Vulnerability" disabled={ readOnly }>
        <PlusIcon />
        <DropdownText>Add Vulnerability</DropdownText>
      </DropdownWrapper>
      {showDropdown && (
      <Dropdown>
        <DropdownItem onClick={ handleShow } role="button" aria-label="Manual Creation" type="button">
          <EditLine size="1.3em" color="#5a6b7d" />
          Manual Creation
        </DropdownItem>
        <DropdownItem onClick={ handleShowImportFromFile } role="button" aria-label="Import from File" type="button">
          <DownloadLine size="1.3em" color="#5a6b7d" />
          Import from File
        </DropdownItem>
      </Dropdown>
      )}

      { showModalCreation && <ManualCreationModal handleClose={ handleClose } />}
      <ImportFileCreationModal show={ showModalImport } handleClose={ handleCloseImportFromFile } />
    </Wrapper>
  );
};

export default CreateActions;
