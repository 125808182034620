import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectCount, selectPage, selectServiceRowsPerPage,
  selectShowDeleteConfirmation
} from 'store/Contextualization/AssetDetail/selectors';
import { setServicesPageNumber } from 'store/Contextualization/AssetDetail/actions';
import CreateActions from 'Screens/Contextualization/Services/components/ActionBar/components/CreateActions';
import { Wrapper, LeftGroup, RightGroup, Separator, Group } from 'Common/Components/Contextualization/CustomActionBar/styled';
import DeleteServiceButton from './components/DeleteServiceButton';
import EditServiceButton from './components/EditServiceButton';
import Pagination from 'Common/Components/Pagination';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';

import CopyLink from './components/CopyLink';
import CopyApiLink from './components/CopyApiLink';
import RefreshButton from './components/RefreshButton';
import AddRemoveColumnsButton from 'Screens/Contextualization/Services/components/ActionBar/components/AddRemoveColumnsButton';
import TagButton from 'Screens/Contextualization/Manage/components/CustomActionBar/components/TagButton';
import { selectAssetServicesFields } from 'store/Preferences/assetServices/selectors';

const ActionBar = ({ setFF }) => {
  const dispatch = useDispatch();
  const servicesCount = useSelector((state) => selectCount('services', state));
  const page = useSelector((state) => selectPage('services', state));
  const rowsPerPage = useSelector((state) => selectServiceRowsPerPage(state));
  const onPrev = () => dispatch(setServicesPageNumber(page - 1));
  const onNext = () => dispatch(setServicesPageNumber(page + 1));
  const hasServices = servicesCount > 0;
  const fields = useSelector(selectAssetServicesFields);
  const showDeleteConfirmation = useSelector((state) => selectShowDeleteConfirmation('services', state));

  return (
    <Wrapper>
      { showDeleteConfirmation ? <DeleteConfirmationModal /> : null }
      <LeftGroup>
        <CreateActions />
        <Separator />
        <Group>
          <EditServiceButton hasServices={ hasServices } />
          <TagButton setFF={ setFF } hasItems={ hasServices } />
          <CopyLink hasServices={ hasServices } />
          <CopyApiLink hasServices={ hasServices } />
          <DeleteServiceButton hasServices={ hasServices } />
        </Group>
      </LeftGroup>

      <RightGroup>
        { hasServices &&
          <>
            <Pagination offset={ page } limit={ rowsPerPage } total={ servicesCount } onPrev={ onPrev } onNext={ onNext } />
            <Separator />
          </>
        }
        <Group>
          <RefreshButton />
          <AddRemoveColumnsButton fields={ fields } />
        </Group>
      </RightGroup>
    </Wrapper>
  );
};

export default ActionBar;
