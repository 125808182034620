import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CreateActions from 'Screens/Contextualization/Host/components/CreateActions';
import { Wrapper, LeftGroup, RightGroup, Separator, Group } from 'Common/Components/Contextualization/CustomActionBar/styled';
import DeleteHostButton from './components/DeleteHostButton';
import EditHostButton from './components/EditHostButton';
import Pagination from 'Common/Components/Pagination';
import {
  toggleModalEditCreate,
  setPageNumberAssets
} from 'store/Host/actions';
import { selectShowHostDetail } from 'store/Contextualization/AssetDetail/selectors';
import { selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import {
  selectHostCount,
  selectHostsSelected,
  selectShowDeleteConfirmation
} from 'store/Host/selectors';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';
import GroupBy from './components/GroupBy';
import FilterSideBarIcon from 'Screens/Contextualization/Manage/components/CustomActionBar/components/FilterSidebarIcon';
import RefreshButton from 'Screens/Contextualization/Host/components/RefreshButton';
import AddRemoveColumnsButton from './components/AddRemoveColumnsButton';
import TagButton from 'Screens/Contextualization/Manage/components/CustomActionBar/components/TagButton';

const ActionBar = ({ setFF }) => {
  const dispatch = useDispatch();
  const hostsSelected = useSelector(selectHostsSelected);
  const hostsCount = useSelector(selectHostCount);
  const isHostDetailVisible = useSelector(selectShowHostDetail);
  const page = useSelector((state) => selectPage('assets', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('assets', state));
  const onPrev = () => dispatch(setPageNumberAssets(page - 1));
  const onNext = () => dispatch(setPageNumberAssets(page + 1));
  const hasHosts = hostsCount > 0;
  const showGroupBy = hasHosts && (hostsSelected.length === 0) && !isHostDetailVisible;
  const showDeleteConfirmation = useSelector(selectShowDeleteConfirmation);

  return (
    <Wrapper>
      { showDeleteConfirmation ? <DeleteConfirmationModal /> : null}
      <LeftGroup>
        <CreateActions setVisibilityCreateModal={ (value) => dispatch(toggleModalEditCreate(value)) } />
        <Separator />
        <Group>
          <EditHostButton hasHosts={ hasHosts } />
          <TagButton setFF={ setFF } hasItems={ hasHosts } />
          <DeleteHostButton hasHosts={ hasHosts } />
        </Group>
      </LeftGroup>

      <RightGroup>
        { showGroupBy && <GroupBy /> }
        { (hasHosts) && <Pagination offset={ page } limit={ rowsPerPage } total={ hostsCount } onPrev={ onPrev } onNext={ onNext } />}
        { (hasHosts) && <Separator /> }
        <Group>
          <RefreshButton />
          <FilterSideBarIcon entity="assets" />
          <AddRemoveColumnsButton />
        </Group>
      </RightGroup>
    </Wrapper>
  );
};

export default ActionBar;
