import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getRequestUrl } from 'services/api/connector';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import copyToClipboard from 'utils/clipboard';
import CopyApiLinkIcon from './styled';
import IconButton from 'Common/Components/IconButton';
import ModalWarning from 'Common/Components/ModalWarning';

const CopyApiLink = ({ hasVulns }) => {
  const [showWarning, setShowWarning] = useState(false);
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'manage.detail.tab.copyApiLink' });
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'vulnerability' });
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const vulnsSelected = useSelector((state) => get(state, 'manage.vulnsSelected', []));
  const vulnId = get(vulnsSelected, '[0]._id', 0);

  const copyApiLink = () => {
    if (vulnId && workspaceSelected) {
      const vulnPath = `ws/${workspaceSelected}/vulns/${vulnId}`;
      const link = getRequestUrl(vulnPath);
      copyToClipboard(link);
    }
  };

  const handleClick = () => {
    if (hasVulns) {
      if (vulnsSelected.length === 1) copyApiLink();
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <CopyApiLinkIcon /> } title={ title } onClick={ handleClick } disabled={ !hasVulns } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title={ title } description={ message } /> }
    </>
  );
};

export default CopyApiLink;
