import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import { groupSelectContext } from 'Common/styles/style';
import { FormattedMessage } from 'react-intl';
import { selectGroupBy } from 'store/Filters/selectors';
import { setGroupByAssets, clearGroupByAssets } from 'store/Host/actions';
import { ASSETS_GROUP_BY_KEYS } from 'store/Filters/constants';

function GroupBy () {
  const alias = 'group';
  const options = ASSETS_GROUP_BY_KEYS;
  const dispatch = useDispatch();
  const groupBy = useSelector((state) => selectGroupBy('assets', state));

  const SingleValue = ({ children, ...p }) => (
    components.SingleValue && (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.SingleValue { ...p }>
      {`Group By: ${children}`}
    </components.SingleValue>
    )
  );

  return (
    <Select
      styles={ groupSelectContext }
      isSearchable={ false }
      value={ options.filter((opt) => opt.value === groupBy) }
      components={ { SingleValue } }
      placeholder={ <FormattedMessage id={ `${alias}.placeholder` } /> }
      onChange={ (e) => {
        if (e.value.length > 0) {
          dispatch(setGroupByAssets(e.value));
        } else {
          dispatch(clearGroupByAssets());
        }
      } }
      options={ options.filter((opt) => opt.value !== groupBy) }
    />
  );
}

export default GroupBy;
