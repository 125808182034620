import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Wrapper, Group, LeftGroup, RightGroup, Separator
} from './styled';
import { selectRowsPerPageListView, selectShowGridViewContext, selectWorkspacesCount } from 'store/Workspace/selectors';
import { WsAddButton, WsEditButton, WsDeleteButton, MoreOptionsButton } from './components/LeftGroup';
import { RefreshButton, ShowInactive, AddRemoveColumnsButton } from './components/RightGroup';
import { selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import { setPage } from 'Screens/Contextualization/Workspaces/actions/Actions';
import Pagination from 'Common/Components/Pagination';

const WSActionBar = () => {
  const dispatch = useDispatch();
  const showGridView = useSelector(selectShowGridViewContext);
  const workspacesCount = useSelector(selectWorkspacesCount);
  const page = useSelector((state) => selectPage('workspaces', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('workspaces', state));
  const rowsPerPageListView = useSelector(selectRowsPerPageListView);
  const onPrev = () => dispatch(setPage(page - 1));
  const onNext = () => dispatch(setPage(page + 1));
  const hasWorkspaces = workspacesCount > 0;

  return (
    <Wrapper showGridView={ showGridView }>
      <LeftGroup>
        <WsAddButton />
        { (!showGridView || hasWorkspaces) &&
          <>
          <Separator />
          <Group>
            <WsEditButton />
            <WsDeleteButton />
            <MoreOptionsButton />
          </Group>
          </>
        }

      </LeftGroup>
      <RightGroup>
        <ShowInactive />
        <Pagination offset={ page } limit={ showGridView ? rowsPerPage : rowsPerPageListView } total={ workspacesCount } onPrev={ onPrev } onNext={ onNext } />
        <Separator />
        <RefreshButton />
        { !showGridView && <AddRemoveColumnsButton /> }
      </RightGroup>
    </Wrapper>
  );
};

export default WSActionBar;
