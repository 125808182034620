import React from 'react';
import { useSelector } from 'react-redux';
import TabsList from 'Common/Components/TabsList';
import {
  selectTabsWithWorkspace, selectTabs, selectAssetTabs,
  generalView, showTabsWithWs, insideAsset
} from 'store/Tabs/selectors';
import { GridViewButton, ListViewButton } from 'Screens/Contextualization/Workspaces/components/WSActionBar/components/RightGroup';
import GlobalTabs from './styled';

const TabsContext = () => {
  const tabsDataWithWorkspace = useSelector(selectTabsWithWorkspace);
  const tabsData = useSelector(selectTabs);
  const assetsTabsData = useSelector(selectAssetTabs);

  const isWorkspaces = useSelector(generalView);
  const showTabsWithWorkspace = useSelector(showTabsWithWs);
  const showAssetsTabs = useSelector(insideAsset);

  return (
    <>
      { isWorkspaces &&
        <GlobalTabs>
          <TabsList tabsList={ tabsData } />
          <GridViewButton />
          <ListViewButton />
        </GlobalTabs>
      }
      { showTabsWithWorkspace && <TabsList tabsList={ tabsDataWithWorkspace } setLastUrlVisited /> }
      { showAssetsTabs && <TabsList tabsList={ assetsTabsData } setLastUrlVisited /> }
    </>

  );
};
export default TabsContext;
