import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import General from 'Screens/Contextualization/ManageEditCreate/components/ManualCreationModal/components/General';
import ModalWrapper from 'Common/Components/ModalWrapper';
import TechnicalDetails from './components/TechnicalDetails';
import CustomFields from './components/CustomFields';
import Evidence from './components/Evidence';
import {
  Wrapper, ModalBody, Tab, Tabs
} from './styled';
import Header from './components/Header';
import { MODAL_EVIDENCE_WARNING_UPLOAD } from 'store/modals/modals';
import ModalWarning from 'Common/Components/EvidenceTab/components/ModalWarning';
import ReduxModal from 'Common/Components/ReduxModal';
import { selectCurrentHost } from 'store/Host/selectors';
import { addAsset, getServicesByHostIds } from 'Screens/Contextualization/ManageEditCreate/actions/Actions';
import { selectErrorCreateVuln } from 'store/ManageEditCreate/selectors';
import CreationWarning from './components/CreationWarning';

const ManualCreationModal = ({ handleClose }) => {
  const [activeTab, setActiveTab] = useState('general');
  const [assets, setAssets] = useState([]);

  const currentAsset = useSelector(selectCurrentHost);
  const dispatch = useDispatch();
  const showErrorCreateVuln = useSelector(selectErrorCreateVuln);

  useEffect(() => {
    if (Object.keys(currentAsset).length > 0) {
      setAssets(currentAsset);
      dispatch(addAsset(currentAsset));
      dispatch(getServicesByHostIds());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RenderTab = {
    general: <General assets={ assets } setAssets={ setAssets } />,
    technicalDetails: <TechnicalDetails assets={ assets } />,
    customFields: <CustomFields />,
    evidence: <Evidence />
  };

  return (
    <ModalWrapper>
      <Wrapper>
        <Header handleClose={ handleClose } activeTab={ activeTab } setActiveTab={ setActiveTab } />
        <ModalBody>
          <Tabs>
            <Tab onClick={ () => setActiveTab('general') } active={ activeTab === 'general' }>General</Tab>
            <Tab onClick={ () => setActiveTab('technicalDetails') } active={ activeTab === 'technicalDetails' }>Technical Details</Tab>
            <Tab onClick={ () => setActiveTab('customFields') } active={ activeTab === 'customFields' }>Custom Attributes</Tab>
            <Tab onClick={ () => setActiveTab('evidence') } active={ activeTab === 'evidence' }>Evidence</Tab>
          </Tabs>
          {RenderTab[activeTab]}
        </ModalBody>
      </Wrapper>
      <ReduxModal id={ MODAL_EVIDENCE_WARNING_UPLOAD }>
        <ModalWarning />
      </ReduxModal>
      { showErrorCreateVuln && <CreationWarning /> }
    </ModalWrapper>
  );
};

ManualCreationModal.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default ManualCreationModal;
