import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import useClickOutside from 'Hooks/useClickOutside';
import IconButton from 'Common/Components/IconButton';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { selectVulnsSelected } from 'store/Manage/selectors';
import { VULNS_CONTEXT_MENU_FILTER_KEYS } from 'store/Filters/constants';
import { setContextMenuFilter } from 'store/Manage/filterActions';
import { Wrapper, FilterIcon, Dropdown } from './styled';
import ModalWarning from 'Common/Components/ModalWarning';

const FilterBy = ({ hasVulns }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();
  const vulnsSelected = useSelector(selectVulnsSelected);
  const dropdownRef = useRef();
  useClickOutside(dropdownRef, () => setShowDropdown(false));
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'vulnerability' });

  const handleClick = () => {
    if (hasVulns) {
      if (vulnsSelected.length === 1) handleDropdown();
      else setShowWarning(true);
    }
  };

  const handleDropdown = () => setShowDropdown(!showDropdown);

  const setFilter = (filterName) => {
    dispatch(setContextMenuFilter(filterName, vulnsSelected[0]));
    handleDropdown();
  };

  const onClose = () => setShowWarning(false);

  return (
    <Wrapper ref={ dropdownRef }>
      <IconButton icon={ <FilterIcon /> } title={ intl.formatMessage({ id: 'manage.cm.filter' }) } onClick={ handleClick } disabled={ !hasVulns } />
      {showDropdown && (
      <Dropdown>
        {
         VULNS_CONTEXT_MENU_FILTER_KEYS.map(
           (filter) => <MenuItem key={ filter.name } title={ filter.label } onClick={ () => setFilter(filter.name) } />
         )
        }
      </Dropdown>
      )}
       { showWarning && <ModalWarning onCloseCallback={ onClose } title={ intl.formatMessage({ id: 'manage.cm.filter' }) } description={ message } /> }
    </Wrapper>

  );
};

export default FilterBy;
