import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalConfirmation from 'Common/Components/ModalConfirmation';
import { selectIsFetching, selectServicesSelected, selectShowDeleteConfirmation } from 'store/Services/selectors';
import { deleteServicesSelected, hideServiceModalDelete } from 'store/Services/actions';

const DeleteConfirmationModal = () => {
  const dispatch = useDispatch();
  const servicesSelected = useSelector(selectServicesSelected);
  const showDeleteConfirmation = useSelector(selectShowDeleteConfirmation);
  const isFetching = useSelector(selectIsFetching);

  return (
    <ModalConfirmation
      show={ showDeleteConfirmation }
      handleSubmit={ () => dispatch(deleteServicesSelected()) }
      handleClose={ () => dispatch(hideServiceModalDelete()) }
      entity="service"
      count={ servicesSelected.length }
      loading={ isFetching }
    />
  );
};

export default DeleteConfirmationModal;
