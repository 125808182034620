const defaultFields = {
  name: {
    visible: true, order: 1, locked: true, maxWidth: 500, minWidth: 312, defaultWidth: 400
  },
  version: {
    visible: true, order: 2, maxWidth: 78, minWidth: 78, defaultWidth: 78
  },
  port: {
    visible: true, order: 3, maxWidth: 100, minWidth: 85, defaultWidth: 85
  },
  protocol: {
    visible: true, order: 4, maxWidth: 113, minWidth: 113, defaultWidth: 113
  },
  status: {
    visible: true, order: 5, maxWidth: 96, minWidth: 96, defaultWidth: 96
  },
  vulns: {
    visible: true, order: 6, maxWidth: 110, minWidth: 87, defaultWidth: 87
  },
  credentials: {
    visible: true, order: 7, maxWidth: 80, minWidth: 62, defaultWidth: 62
  },
  asset: {
    visible: true, order: 8, maxWidth: 200, minWidth: 150, defaultWidth: 150
  }
};

export default defaultFields;
