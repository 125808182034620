import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { moreStatusChange } from 'Screens/Contextualization/Workspaces/actions/Actions';
import { selectMoreOptionsOpened, selectWorkspacesSelected } from 'store/Workspace/selectors';
import isEmpty from 'lodash/isEmpty';
import IconButton from 'Common/Components/IconButton';
import { ReactComponent as MoreIcon } from 'Images/icon-action-bar-more.svg';
import ContextMenuButton from 'Common/Components/ContextMenuButton';
import ContextMenuWorkspaceMassiveUpdate from 'Screens/Contextualization/Workspaces/components/ContextMenuWorkspaceMassiveUpdate';

// eslint-disable-next-line import/prefer-default-export
export const MoreOptionsButton = () => {
  const dispatch = useDispatch();
  const moreOptionsOpened = useSelector(selectMoreOptionsOpened);
  const selectedWs = useSelector(selectWorkspacesSelected);
  const hasSelectedWs = !isEmpty(selectedWs);

  return (
    <ContextMenuButton
      opened={ moreOptionsOpened }
      menu={ <ContextMenuWorkspaceMassiveUpdate trigger="massive_update_workspaces_table_nav" onShow={ () => dispatch(moreStatusChange(true)) } /> }
      trigger="massive_update_workspaces_table_nav"
      display={ hasSelectedWs }
      icon={ <IconButton icon={ <MoreIcon /> } title="More" /> }
    />
  );
};
