import React, { useState, useRef } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { GET_SUMMARY_SUCCEED_DASHBOARD, showModalImportDashboard } from 'Screens/Dashboard/actions/Actions';
import { FormattedMessage } from 'react-intl';
import ModalCreation from 'Screens/Contextualization/Workspaces/components/ModalCreation';
import { PropTypes } from 'prop-types';
import { saveWorkspaceHistory } from 'Screens/Contextualization/Faraday/actions/Actions';
import selectModal from 'store/modals/selectors';
import { closeModal, openModal } from 'store/modals/actions';
import { selectAllVulnsCount, selectConfirmedVulnsCount, selectCurrentWorkspace, selectFaradayLoading, selectIsAdmin, selectReadOnly, selectUnconfirmedVulnsCount } from 'store/Faraday/selectors';
import useClickOutside from 'Hooks/useClickOutside';
import replace from 'lodash/replace';
import get from 'lodash/get';
import { MODAL_WORKSPACE_CREATE } from 'store/modals/modals';
import { redirect } from 'store/Router/actions';
import { selectLocation } from 'store/Router/selectors';
import { getWorkspacesForSelector, setWsStatsError } from 'Screens/Contextualization/Workspaces/actions/Actions';
import { clearFilters, setPageNumber } from 'store/Filters/actions';
import { newGetVulns } from 'store/Manage/filterActions';
import {
  Wrapper, Toggle, Menu, Item, WorkspaceSelectedName,
  ArrowDown, WorkspaceOptionName, ActionsContainer,
  ViewAll, CreateNew, NoItems, WsIcon, WsWrapper,
  IconWrapper, LockIcon, WsSelected, StyledWrapper
} from './styled';
import { redirectToHost } from 'store/Contextualization/AssetDetail/actions';

import { selectWorkspacesForSelector } from 'store/Workspace/selectors';
import api from 'services/api';
import SpinnerComponent from 'Common/Components/SpinnerContainer';
import formatNumber from 'Common/Functions/FormatNumber';
import { getData } from 'store/Host/actions';
import VulnFlagFilter from 'Common/Components/Contextualization/VulnFlagFilter';
import { getServices } from 'store/Services/actions';

const WorkspaceSelector = ({
  saveWorkspaceHistoryProp, workspaces, workspaceSelected, isReadOnly
}) => {
  const themeList = ['allVulns', 'confirmed', 'notConfirmed'];
  const [theme, setTheme] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  const noWorkspaces = workspaces.length === 0;
  const showNoItem = noWorkspaces || (workspaces.length === 1 && workspaces[0].name === workspaceSelected);
  const dropdownRef = useRef();
  useClickOutside(dropdownRef, () => setShowDropdown(false));
  const location = useSelector(selectLocation);
  const isAdmin = useSelector(selectIsAdmin);
  const isManage = get(location, 'pathname', '').includes('/manage');
  const isAssets = get(location, 'pathname', '').includes('/host') && !(get(location, 'pathname', '').includes('/vulns')) && !(get(location, 'pathname', '').includes('/services'));
  const isServices = get(location, 'pathname', '').includes('/service');
  const isVulnsAssets = get(location, 'pathname', '').includes('/host') && get(location, 'pathname', '').includes('/vulns');
  const isServicesAssets = get(location, 'pathname', '').includes('/host') && get(location, 'pathname', '').includes('/services');
  const isDashboard = get(location, 'pathname', '').includes(`/feed/${workspaceSelected}`);
  const isFetching = useSelector(selectFaradayLoading);
  const allVulnsCount = useSelector(selectAllVulnsCount);
  const confirmedCount = useSelector(selectConfirmedVulnsCount);
  const unconfirmedCount = useSelector(selectUnconfirmedVulnsCount);

  const count = () => {
    if (theme === 0) return formatNumber(allVulnsCount);
    else if (theme === 1) return formatNumber(confirmedCount);
    else return formatNumber(unconfirmedCount);
  };

  const vulnText = () => {
    if (count === 0) return '';
    else if (count > 1) return `(${count()} vulnerabilities)`;
    else return `(${count()} vulnerability)`;
  };

  const redirectTo = (ws) => {
    let url = '';
    if (location.pathname === '/404') {
      url = `/feed/${ws.name}`;
    } else {
      url = replace(location.pathname, workspaceSelected, ws.name);
    }
    dispatch(redirect(`${url}`));
  };

  const selectWorkspace = async (ws) => {
    saveWorkspaceHistoryProp(ws.name);
    redirectTo(ws);
    if (!isDashboard) {
      try {
        const response = await api.workspace.getWsStats(ws.name);
        dispatch({ type: GET_SUMMARY_SUCCEED_DASHBOARD, data: response });
      } catch (error) {
        dispatch(setWsStatsError(error.message));
      }
    }
    if (ws.stats.total_vulns === 0) dispatch(showModalImportDashboard(true));
    if (isManage) {
      dispatch(setPageNumber('vulns', 1));
      dispatch(newGetVulns());
    }
    if (isAssets) {
      dispatch(setPageNumber('assets', 1));
      dispatch(getData());
    }
    if (isServices) dispatch(getServices());
    if (isVulnsAssets || isServicesAssets) dispatch(redirectToHost());
  };

  const getItems = () => {
    if (isFetching) return <SpinnerComponent height="70px" />;
    if (showNoItem) {
      return (
        <NoItems><FormattedMessage id="workspaces.contextMenu.no_other" /></NoItems>
      );
    }

    return workspaces.map((ws, index) => {
      if (ws.name !== workspaceSelected) {
        return (
          <Item id={ `ws-selector-${ws.name}` } onClick={ () => { selectWorkspace(ws); setShowDropdown(false); dispatch(clearFilters('vulns')); return false; } } key={ ws.name } lastelement={ index + 1 === workspaces.length }>
            <WorkspaceOptionName>{ws.name}</WorkspaceOptionName>
            {/* <WorkspaceVulns>{ws.stats?.total_vulns ?? 'N/A' }</WorkspaceVulns> */}
            {/* <WorkspaceVulns>{formatNumber(ws.stats.total_vulns)}</WorkspaceVulns> */}
          </Item>
        );
      } return null;
    });
  };

  const show = useSelector((state) => selectModal(state, MODAL_WORKSPACE_CREATE));
  const handleClose = () => dispatch(closeModal(MODAL_WORKSPACE_CREATE));
  const handleOpen = () => dispatch(openModal(MODAL_WORKSPACE_CREATE));
  const handleToggle = () => {
    setShowDropdown(!showDropdown);
    if (!showDropdown) {
      dispatch(getWorkspacesForSelector());
    }
  };

  return (
    <Wrapper ref={ dropdownRef } data-tour="Workspace Selector" >
      <StyledWrapper>
      <Toggle onClick={ handleToggle }>
        <IconWrapper><WsIcon /></IconWrapper>
        <WsWrapper>
          <WsSelected>
            { isReadOnly && <LockIcon />}
            <WorkspaceSelectedName id={ `ws-selector-selected-${workspaceSelected}` } title={ workspaceSelected || 'No Workspace Selected' }>
            {`${workspaceSelected} ${vulnText()}` || 'No Workspace Selected'}
            </WorkspaceSelectedName>
          </WsSelected>
          <ArrowDown />
        </WsWrapper>
      </Toggle>
      <VulnFlagFilter theme={ theme } setTheme={ setTheme } themeList={ themeList } />
      </StyledWrapper>
      { workspaces.length >= 0 && showDropdown && (
        <Menu>
          {getItems()}
          <ActionsContainer>
            {!noWorkspaces && <ViewAll onClick={ () => { setShowDropdown(false); dispatch(redirect('/workspaces')); } }><FormattedMessage id="workspaces.contextMenu.viewAll" /></ViewAll>}
            { isAdmin && <CreateNew onClick={ () => { setShowDropdown(false); handleOpen(); } }><FormattedMessage id="workspaces.contextMenu.createNew" /></CreateNew> }
          </ActionsContainer>
        </Menu>
      ) }
      {show && <ModalCreation show handleClose={ handleClose } /> }
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  workspaces: selectWorkspacesForSelector(state),
  workspaceSelected: selectCurrentWorkspace(state),
  isReadOnly: selectReadOnly(state)
});

const mapDispatchToProps = (dispatch) => ({
  saveWorkspaceHistoryProp: (ws) => {
    dispatch(saveWorkspaceHistory(ws));
  }
});

WorkspaceSelector.propTypes = {
  workspaces: PropTypes.instanceOf(Object).isRequired,
  workspaceSelected: PropTypes.string.isRequired,
  saveWorkspaceHistoryProp: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspaceSelector));
