import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createVulnerability, resetState, setCreateVulnError } from 'Screens/Contextualization/ManageEditCreate/actions/Actions';
import {
  selectNameField,
  selectDescriptionField,
  selectSeverityField,
  selectTargetsField,
  selectIsWebVuln
} from 'store/ManageEditCreate/selectors';
import { Cancel } from 'Common/Components/Button/styled';
import isEmpty from 'lodash/isEmpty';
import { PropTypes } from 'prop-types';
import {
  Wrapper, Title, ModalIcon, ButtonWrapper, CreateButton, NextIcon
} from './styled';
import { selectCurrentHost } from 'store/Host/selectors';
import { createVulnFromAsset } from 'store/Contextualization/AssetDetail/actions';
import { useIntl } from 'react-intl';

const ManualCreationModal = ({ handleClose, setActiveTab }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const name = useSelector(selectNameField);
  const description = useSelector(selectDescriptionField);
  const severity = useSelector(selectSeverityField);
  const targets = useSelector(selectTargetsField);
  const isWebVuln = useSelector(selectIsWebVuln);
  const hasTargets = !isEmpty(targets);
  const assetForWorking = useSelector(selectCurrentHost);
  const createFromAsset = Object.keys(assetForWorking).length > 0;
  const servicesTargets = targets.filter((target) => target.type === 'Service');
  const hasServicesTargets = !isEmpty(servicesTargets);
  const errorMessage = intl.formatMessage({ id: 'modal.createVuln.cantCreate.message' });
  const errorTitle = intl.formatMessage({ id: 'modal.createVuln.cantCreate.title' });

  const validTargets = () => {
    if (isWebVuln) return hasServicesTargets;
    return hasTargets;
  };

  const onHandleClose = () => {
    handleClose();
    setActiveTab('general');
    dispatch(resetState());
  };

  const handleCreateVuln = () => {
    if (validTargets()) {
      if (createFromAsset) dispatch(createVulnFromAsset());
      else dispatch(createVulnerability());
      if (hasTargets) onHandleClose();
    }
  };

  const text = 'Create';

  const mandatoryFieldsFilled = !isEmpty(name) && !isEmpty(description) && !isEmpty(severity);
  const enabled = mandatoryFieldsFilled;

  const showErrorModal = () => dispatch(setCreateVulnError(true, errorMessage, errorTitle));

  return (
    <Wrapper>
      <Title children="Create New Vulnerability" />
      <ButtonWrapper>
        <Cancel onClick={ onHandleClose } children="Cancel" />
        <CreateButton
          onClick={ enabled ? handleCreateVuln : showErrorModal }
        >
          <span children={ text } />
          <NextIcon />
        </CreateButton>
        <ModalIcon />
      </ButtonWrapper>
    </Wrapper>
  );
};

ManualCreationModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired
};

export default ManualCreationModal;
