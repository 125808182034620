import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as _PlusIcon } from 'Images/icon-action-bar-plus.svg';

export const DropdownText = styled.span`
  font-weight: 500;
  color: ${colors.dark2};
  font-size: 11.5px;
  user-select: none;
  width: 92px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
DropdownText.displayName = 'DropdownText';

export const DropdownItem = styled.div`
height: 34px;
padding: 10px 17px;
display: flex;
align-items: center;

svg {
  position: relative;
  margin-right: 15px;
}

&:hover {
  background-color: #f1f4f7;
  font-weight: 600;
}
  font-size: 12px;
  font-weight: 300;
`;
DropdownItem.displayName = 'DropdownItem';

export const DropdownWrapper = styled.div`
background-color: transparent;
  padding: 0px 10px;
  font-size: 11.5px;
  font-weight: 500;
  line-height: 34px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px rgba(144, 169, 192, 0.3);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border-radius: 3px;
    border: solid 1px #d8dee5;
    background-color: #f1f4f7;
  }

  &:active {
    border: solid 1px #bcccd9;
    background-color: #dde5ec;
  }

  ${({ disabled }) => {
    if (!disabled) return null;
    return `
      pointer-events: none;
      background-color: ${colors.white};
      & > span {
        color: ${colors.grey26};
      }
      & > svg {
        & > path {
          fill: ${colors.grey26};
        }
      }
      `;
  }}
`;

export const Wrapper = styled.div`
  position: relative;
`;
Wrapper.displayName = 'Wrapper';

export const PlusIcon = styled(_PlusIcon)`
height: 11px;
margin-right: 10px;
& > path {
  fill: #1c2243;
}
`;

export const Dropdown = styled.div`
  position: absolute;
  width: 244px;
    height: 76px;
    border-radius: 3px;
    box-shadow: 2px 2px 30px 0 #d5dee6;
    border: solid 1px #cdd5dd;
    background-color: #ffffff;
    padding: 3px 4px;
    z-index: 1000;
`;
