import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalConfirmation from 'Common/Components/ModalConfirmation';
import { selectIsFetching, selectSelected, selectShowDeleteConfirmation } from 'store/Contextualization/AssetDetail/selectors';
import { deleteServicesSelected, hideModalDelete } from 'store/Contextualization/AssetDetail/actions';

const DeleteConfirmationModal = () => {
  const dispatch = useDispatch();
  const servicesSelected = useSelector((state) => selectSelected('services', state));
  const showDeleteConfirmation = useSelector((state) => selectShowDeleteConfirmation('services', state));
  const isFetching = useSelector((state) => selectIsFetching('services', state));

  return (
    <ModalConfirmation
      show={ showDeleteConfirmation }
      handleSubmit={ () => dispatch(deleteServicesSelected()) }
      handleClose={ () => dispatch(hideModalDelete('services')) }
      entity="service"
      count={ servicesSelected.length }
      loading={ isFetching }
    />
  );
};

export default DeleteConfirmationModal;
