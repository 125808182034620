import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { closeModal } from 'store/modals/actions';
import { MODAL_CANT_CREATE } from 'store/modals/modals';
import ConfirmNavigationModal from 'Common/Components/ConfirmNavigationModal';
import { redirectToHost } from 'store/Contextualization/AssetDetail/actions';

const WarningModal = ({ entity }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const cantCreate = intl.formatMessage({ id: 'modal.cantCreate' });
  const title = `${cantCreate} ${entity}`;
  const message = intl.formatMessage({ id: 'modal.cantCreate.vulns' });
  const cancel = intl.formatMessage({ id: 'modal.cantCreate.toAssets' });

  const onDone = () => {
    dispatch(closeModal(MODAL_CANT_CREATE));
    dispatch(redirectToHost());
  };

  const onCancel = () => {
    dispatch(closeModal(MODAL_CANT_CREATE));
  };

  return (
    <ConfirmNavigationModal
      title={ title }
      message={ message }
      onCancel={ onCancel }
      onDone={ onDone }
      cancel="Close"
      done={ cancel }
    />
  );
};

export default WarningModal;
