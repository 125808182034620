import api from 'services/api';
import {
  selectHostList, selectHostsSelected, selectHostCount, selectLastSelected, selectCurrentHost,
  selectContextMenuXPos,
  selectContextMenuYPos,
  selectModalBulkUpdateField,
  selectModalBulkUpdateValue,
  selectSelectAllHosts
} from 'store/Host/selectors';
import {
  selectQueryParam,
  selectExpandedQueryParam,
  selectIsGrouping,
  selectRowsPerPage,
  selectAdvancedFilterQueryParam,
  selectAdvancedExpandedQueryParam
} from 'store/Filters/selectors';
import {
  expandGroupBy,
  setGroupBy,
  clearGroupBy,
  setOrderBy,
  setPageNumber,
  setFilterError
} from 'store/Filters/actions';
import get from 'lodash/get';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import * as types from './types';
import { getVulns, setHostForWorking, showAssetDetail } from 'store/Contextualization/AssetDetail/actions';
import { selectHostDetail, selectHostDetailId } from 'store/HostDetail/selectors';
import { selectAssetDetailId } from 'store/Contextualization/AssetDetail/selectors';
import { showHostDetail } from 'store/HostDetail/actions';
import isEmpty from 'lodash/isEmpty';
import { closeModal, openModal } from 'store/modals/actions';
import { MODAL_MANAGE_BULK_UPDATE, MODAL_MANAGE_BULK_UPDATE_CONFIRMATION } from 'store/modals/modals';

const setHostsFilterError = () => async (dispatch) => dispatch({ type: types.SET_HOSTS_FILTER_ERROR });

const getDataStartCallback = () => async (dispatch) => dispatch({ type: types.GET_DATA_HOST_START });
const getDataSuccedCallback = (data, hostCount) => async (dispatch) => dispatch({ type: types.GET_DATA_HOST_SUCCESS, data, hostCount });
const getDataFailureCallback = (error) => async (dispatch) => dispatch({ type: types.GET_DATA_HOST_FAIL, error });

// Summary: Get data to populate on table, with pagination and/or sort.
export function getData () {
  return async (dispatch, getState) => {
    dispatch(getDataStartCallback());

    const state = getState();

    let advancedFilterQueryParam = [];
    try {
      advancedFilterQueryParam = selectAdvancedFilterQueryParam(state, 'assets');
    } catch (e) {
      dispatch(setFilterError('assets', 'Syntax error. Please try again. For further help check our documentation'));
      return dispatch(setHostsFilterError());
    }
    const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
    const standardQueryParam = selectQueryParam('assets', state);
    const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

    try {
      const workspace = selectCurrentWorkspace(getState());
      const response = await api.host.fetchHosts(workspace, queryParam);
      const data = response.rows.map((x) => x.value);

      return dispatch(getDataSuccedCallback(data, response.count));
    } catch (e) {
      const errorMessage = get(e, 'message', 'Failed to obtain hosts.');
      if (errorMessage.includes('filter')) {
        dispatch(setFilterError('assets', 'Invalid filters. Please try again. For further help check our documentation'));
        return dispatch(setHostsFilterError());
      }
      return dispatch(getDataFailureCallback(errorMessage));
    }
  };
}

export function updateHostList (hostList) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_DATA_HOST_LIST, hostList });
  };
}

export function appendHostListRow (hostRow) {
  return (dispatch) => {
    dispatch({ type: types.APPEND_DATA_HOST_LIST, hostRow });
  };
}

export function updateHostListRow (hostRow) {
  return async (dispatch, getState) => {
    const state = getState();
    const hostList = selectHostList(state);
    const newHostList = [...hostList];
    const hostIndex = newHostList.findIndex((h) => h.id === hostRow.id);
    if (hostIndex >= 0) {
      newHostList[hostIndex] = hostRow;
      dispatch(updateHostList(newHostList));
    }
  };
}

export function setOrderByAssets (sorting) {
  return (dispatch, getState) => {
    const state = getState();
    const isGrouping = selectIsGrouping('assets', state);

    dispatch(setOrderBy('assets', sorting));
    if (!isGrouping) dispatch(getData());
  };
}

export function setPageNumberAssets (pageNumber) {
  return (dispatch) => {
    dispatch(setPageNumber('assets', pageNumber));
    dispatch(getData());
  };
}

// ------------- GROUP BY ACTIONS -------------

export function getExpandedGroupByData (index) {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(getDataStartCallback());

    let advancedFilterQueryParam = [];
    try {
      advancedFilterQueryParam = selectAdvancedExpandedQueryParam(state, 'assets');
    } catch (e) {
      dispatch(setFilterError('assets', 'Syntax error. Please try again. For further help check our documentation'));
      return dispatch(setHostsFilterError());
    }
    const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
    const standardQueryParam = selectExpandedQueryParam('assets', state);
    const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

    try {
      const workspace = selectCurrentWorkspace(getState());
      const response = await api.host.fetchHosts(workspace, queryParam);
      const data = response && response.rows ? response.rows.map((data) => data.value) : [];
      return dispatch({ type: types.GET_DATA_HOST_GROUP_BY_EXPAND, index, data });
    } catch (e) {
      const errorMessage = get(e, 'message', 'Failed to obtain hosts.');
      if (errorMessage.includes('filter')) {
        dispatch(setFilterError('assets', 'Invalid filters. Please try again. For further help check our documentation'));
        return dispatch(setHostsFilterError());
      }
      return dispatch(getDataFailureCallback(errorMessage));
    }
  };
}

export function clearExpandedGroupByData () {
  return async (dispatch) => dispatch({ type: types.GET_DATA_HOST_GROUP_BY_RESET });
}

export function clearGroupByAssets () {
  return async (dispatch) => {
    await dispatch(clearGroupBy('assets'));
  };
}

export function setGroupByAssets (group_by) {
  return async (dispatch) => {
    const field = get(group_by, '[0].field', '');
    await dispatch(setGroupBy('assets', group_by));
    if (field) await dispatch(setOrderBy('assets', [{ id: field, desc: false }]));
    dispatch(getData());
  };
}

export function expandGroupByAssets (index, value) {
  return async (dispatch) => {
    await dispatch(expandGroupBy('assets', value));
    dispatch(getExpandedGroupByData(index));
  };
}

// -------------  -------------

export function toggleModalEditCreate (value, host) {
  return (dispatch) => {
    dispatch({ type: types.TOGGLE_MODAL_EDIT_CREATE_HOST, value, host });
  };
}

export function resetState () {
  return (dispatch) => {
    dispatch({ type: types.RESET_STATE_HOST });
  };
}

// Summary: Show confirmation modal when user delete host/s
export function showHostModalDelete () {
  return (dispatch) => {
    dispatch({ type: types.SHOW_MODAL_DELETE_HOSTS });
  };
}

// Summary: Hide confirmation modal when user delete host/s
export function hideHostModalDelete () {
  return (dispatch) => {
    dispatch({ type: types.HIDE_MODAL_DELETE_HOSTS });
  };
}

export function deleteHostsSelected () {
  return async (dispatch, getState) => {
    dispatch({ type: types.DELETE_HOSTS_START });
    const { workspaceSelected } = getState().faraday;
    const hostselected = selectHostsSelected(getState());
    const hostSelectedID = hostselected.map(host => host._id);
    try {
      const response = await api.host.deleteHost(workspaceSelected, hostSelectedID);
      dispatch({ type: types.DELETE_HOSTS_SUCCESS, response });

      dispatch(hideHostModalDelete());
      return dispatch(getData());
    } catch (err) {
      dispatch({ type: types.DELETE_HOSTS_FAIL });
    }
  };
}

export function showHostLeftFilters (visible) {
  return (dispatch) => {
    dispatch({ type: types.SHOW_HOST_LEFT_FILTERS, visible });
  };
}

// Click Logic

const addDeleteController = (hosts, hostList) => { // if all hosts are in hostList then unselect then if not
  const allHostsAreSelected = hosts.every((testHost) => hostList.some((host) => host._id === testHost._id));

  if (allHostsAreSelected) return [types.HOST_UNSELECTED, hosts];
  return [types.HOST_SELECTED, hosts];
};

const selectCalculator = (e, host, hostList, areHostSelected) => (dispatch, getState) => {
  const pivot = selectLastSelected(getState());
  const index = hostList.findIndex((el) => el._id === host._id);
  const hostsSelected = selectHostsSelected(getState());

  dispatch({ type: types.NEW_PIVOT, payload: index });
  if (e.shiftKey && pivot !== -1 && areHostSelected) { // if already had hosts selected and shift key is pressed
    const start = Math.min(pivot, index);
    const end = Math.max(pivot, index) + 1;
    const hosts = hostList.slice(start, end);
    const [type, payload] = addDeleteController(hosts, hostsSelected);
    return dispatch({ type, payload });
  }
  const [type, payload] = addDeleteController([host], hostsSelected);
  return dispatch({ type, payload });
};

export const selectRow = (e, host) => (dispatch, getState) => {
  const allHost = selectHostList(getState());
  const isGroupingBy = selectIsGrouping('assets', getState());
  const hostSelected = selectHostsSelected(getState());

  if (isGroupingBy) {
    const group = allHost.find((g) => g.groupData && g.groupData.find((el) => el._id === host._id));
    if (!group) return; // si no hay groupData no se puede hacer calculos con las hosts
    const hostGroup = group.groupData;
    const areHostSelected = hostSelected.length > 0 && hostGroup.some((el) => el.id === host.id);
    dispatch(selectCalculator(e, host, hostGroup, areHostSelected, hostSelected));
  } else {
    dispatch(selectCalculator(e, host, allHost, hostSelected.length > 0));
  }
};

export const unSelectAll = () => (dispatch) => dispatch({ type: types.UNSELECT_ALL_HOST });

export const selectAllHost = () => (dispatch, getState) => {
  const hostsList = selectHostList(getState());
  const hostsSelected = selectHostsSelected(getState());
  const hostCount = selectHostCount(getState());
  const pageSize = selectRowsPerPage('assets', getState());

  if (hostsSelected.length === hostCount || hostsSelected.length >= pageSize) return dispatch(unSelectAll());

  return dispatch({ type: types.SELECT_ALL_HOST, hostsList });
};

export function updateHost (hostId, valueObj) {
  return async (dispatch, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    const hostList = selectHostList(getState());
    const hostCount = selectHostCount(getState());
    const hostDetailId = selectHostDetailId(getState());
    const newHost = await api.host.updateHost(workspaceSelected, { id: hostId, ...valueObj });
    const index = hostList.findIndex((x) => hostId === x.id || hostId === x._id);
    hostList[index] = { ...newHost, severity_counts: hostList[index].severity_counts };
    dispatch({ type: types.GET_DATA_HOST_SUCCESS, data: hostList, hostCount });
    if (hostDetailId && newHost._id === hostDetailId) dispatch(showHostDetail(newHost));
  };
}

// Context
export function updateAsset (hostId, valueObj) {
  return async (dispatch, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    const hostList = selectHostList(getState());
    const hostCount = selectHostCount(getState());
    const hostDetailId = selectAssetDetailId(getState());
    const newHost = await api.host.updateHost(workspaceSelected, { id: hostId, ...valueObj });
    const index = hostList.findIndex((x) => hostId === x.id || hostId === x._id);
    hostList[index] = { ...newHost, severity_counts: hostList[index].severity_counts };
    dispatch({ type: types.GET_DATA_HOST_SUCCESS, data: hostList, hostCount });
    if (hostDetailId && newHost._id === hostDetailId) dispatch(showAssetDetail(newHost));
  };
}

export function setOrderByVulnsInHost (sorting) {
  return async (dispatch, getState) => {
    const state = getState();
    const host = selectCurrentHost(state);
    const hostIp = get(host, 'ip', '');
    dispatch(setOrderBy('vulnsAssets', sorting));
    if (hostIp) await dispatch(getVulns(host.ip));
    dispatch(setHostForWorking(host));
  };
}

export function removeCurrentHost () {
  return (dispatch) => {
    dispatch({ type: types.REMOVE_CURRENT_HOST });
  };
}

export function appendAssetListRow (hostRow) {
  return (dispatch) => {
    dispatch({ type: types.ASSETS_APPEND_DATA_HOST_LIST, hostRow });
  };
}

const addDeleteControllerAsset = (hosts, hostList) => { // if all hosts are in hostList then unselect then if not
  const allHostsAreSelected = hosts.every((testHost) => hostList.some((host) => host._id === testHost._id));

  if (allHostsAreSelected) return [types.ASSET_UNSELECTED, hosts];
  return [types.ASSET_SELECTED, hosts];
};

const selectCalculatorAsset = (e, host, hostList, areHostSelected) => (dispatch, getState) => {
  const pivot = selectLastSelected(getState());
  const index = hostList.findIndex((el) => el._id === host._id);
  const hostsSelected = selectHostsSelected(getState());

  dispatch({ type: types.NEW_PIVOT, payload: index });
  if (e.shiftKey && pivot !== -1 && areHostSelected) { // if already had hosts selected and shift key is pressed
    const start = Math.min(pivot, index);
    const end = Math.max(pivot, index) + 1;
    const hosts = hostList.slice(start, end);
    const [type, payload] = addDeleteControllerAsset(hosts, hostsSelected);
    return dispatch({ type, payload });
  }
  const [type, payload] = addDeleteControllerAsset([host], hostsSelected);
  return dispatch({ type, payload });
};

export const selectAssetRow = (e, host) => (dispatch, getState) => {
  const allHost = selectHostList(getState());
  const isGroupingBy = selectIsGrouping('assets', getState());
  const hostSelected = selectHostsSelected(getState());

  if (isGroupingBy) {
    const group = allHost.find((g) => g.groupData && g.groupData.find((el) => el._id === host._id));
    if (!group) return; // si no hay groupData no se puede hacer calculos con las hosts
    const hostGroup = group.groupData;
    const areHostSelected = hostSelected.length > 0 && hostGroup.some((el) => el.id === host.id);
    dispatch(selectCalculatorAsset(e, host, hostGroup, areHostSelected, hostSelected));
  } else {
    dispatch(selectCalculatorAsset(e, host, allHost, hostSelected.length > 0));
  }
};

export const selectTotalHosts = () => (dispatch, getState) => {
  return dispatch({ type: types.SELECT_TOTAL_ASSETS });
};

export const unselectTotalHosts = () => (dispatch, getState) => {
  return dispatch({ type: types.UNSELECT_TOTAL_ASSETS });
};

export const showContextMenu = (show, XPos, YPos) => {
  return (dispatch, getState) => {
    const state = getState();
    const currentXPos = selectContextMenuXPos(state);
    const currentYPos = selectContextMenuYPos(state);
    const newXPos = XPos ? (XPos + 1) : currentXPos;
    const newYPos = YPos ? (YPos + 1) : currentYPos;

    dispatch({
      type: types.ASSETS_SHOW_CONTEXT_MENU, show, contextMenuXPos: newXPos, contextMenuYPos: newYPos
    });
  };
};

export const setBulkUpdateField = (field) => (dispatch) => dispatch({ type: types.ASSETS_SET_BULK_UPDATE_FIELD, field });

export const setBulkUpdateValue = (value) => (dispatch) => dispatch({ type: types.ASSETS_SET_BULK_UPDATE_VALUE, value });

export const addBulkUpdateValue = (value) => (dispatch) => dispatch({ type: types.ASSETS_ADD_BULK_UPDATE_VALUE, value });

export const removeBulkUpdateValue = (value) => (dispatch) => dispatch({ type: types.ASSETS_REMOVE_BULK_UPDATE_VALUE, value });

export const refreshAssetsList = (assetsList, assetsSelected, assetDetail) => (dispatch, getState) => {
  dispatch({
    type: types.ASSETS_REFRESH_LIST, assetsList, assetsSelected, assetDetail, assetsCount: selectHostCount(getState())
  });
};

export function bulkUpdateAssets () {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch({ type: types.ASSETS_CONFIRMATION_CHANGE_START });

    try {
      const assetsList = selectHostList(state);
      const assetsSelected = selectHostsSelected(state);
      const assetDetail = selectHostDetail(state);
      const field = selectModalBulkUpdateField(state);
      const value = selectModalBulkUpdateValue(state);
      const workspaceSelected = selectCurrentWorkspace(state);
      const selectAll = selectSelectAllHosts(state);
      const assetIDs = assetsSelected.map((v) => v._id);

      const advancedFilterQueryParam = selectAdvancedFilterQueryParam(state, 'assets');
      const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
      const standardQueryParam = selectQueryParam('assets', state);
      const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

      let data = {};

      if (field === 'hostnames') data = { hostnames: value };
      else data = { [field]: value };

      const isDetailAssetSelected = !isEmpty(assetDetail) && assetsSelected.some((selectedAsset) => selectedAsset._id === assetDetail?._id);

      const updatedAssetDetail = isDetailAssetSelected ? { ...assetDetail, ...data } : { ...assetDetail };

      if (selectAll) {
        await api.host.updateAllHosts(workspaceSelected, { filters: queryParam.filters }, data);

        const updatedAssetsList = assetsList.map((asset) => ({ ...asset, ...data }));
        const updatedSelectedAssets = assetsSelected.map((asset) => ({ ...asset, ...data }));

        dispatch(refreshAssetsList(updatedAssetsList, updatedSelectedAssets, updatedAssetDetail));
      } else {
        const payload = { ids: assetIDs, ...data };

        await api.host.updateHosts(workspaceSelected, payload);

        const updatedAssetsList = assetsList.map((asset) => {
          const isSelected = assetsSelected.some((selectedAsset) => selectedAsset._id === asset._id);
          if (isSelected) return { ...asset, ...data };
          return asset;
        });

        const updatedSelectedAssets = assetsSelected.map((asset) => ({ ...asset, ...data }));

        dispatch({ type: types.ASSETS_SET_BULK_UPDATE_SUCCESS });
        dispatch(refreshAssetsList(updatedAssetsList, updatedSelectedAssets, updatedAssetDetail));
      }

      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE));
      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
      dispatch({ type: types.ASSETS_BULK_UPDATE_FINISHED });
    } catch (e) {
      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE));
      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
      dispatch({ type: types.ASSETS_ERROR, errorMessage: e.message || 'An error occured while updating vulns' });
    }
  };
}

export const showBulkUpdateModal = (bulkUpdateField, bulkUpdateValue) => {
  return (dispatch) => {
    dispatch(setBulkUpdateField(bulkUpdateField));
    dispatch(setBulkUpdateValue(bulkUpdateValue));
    dispatch(openModal(MODAL_MANAGE_BULK_UPDATE));
  };
};

export function deleteAssetsSelected () {
  return async (dispatch, getState) => {
    dispatch({ type: types.DELETE_HOSTS_START });
    const { workspaceSelected } = getState().faraday;
    const hostselected = selectHostsSelected(getState());
    const hostSelectedID = hostselected.map(host => host._id);
    const selectAll = selectSelectAllHosts(getState());

    const advancedFilterQueryParam = selectAdvancedFilterQueryParam(getState(), 'assets');
    const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
    const standardQueryParam = selectQueryParam('assets', getState());
    const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

    try {
      if (selectAll) {
        await api.host.deleteAllHosts(workspaceSelected, { filters: queryParam.filters });
      } else {
        const response = await api.host.deleteHost(workspaceSelected, hostSelectedID);
        dispatch({ type: types.DELETE_HOSTS_SUCCESS, response });
      }

      dispatch(hideHostModalDelete());
      return dispatch(getData());
    } catch (err) {
      dispatch({ type: types.DELETE_HOSTS_FAIL });
    }
  };
}
