import React, { useState, useEffect } from 'react';
import Layout from 'Screens/Contextualization/Dashboard/components/Layout';
import ImportFileCreationModal from 'Screens/Contextualization/ManageEditCreate/components/ImportFileCreationModal';
import { getTopHosts, getVulnsCount, getSummary } from 'Screens/Contextualization/Dashboard/actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllHostsCount, selectAllVulnsCount, selectCurrentWorkspace } from 'store/Faraday/selectors';

import EmptyVulns from 'Common/Components/EmptyVulns';
import emptyFeed from 'Images/empty-feed.png';
import ManualCreationModal from 'Screens/Contextualization/ManageEditCreate/components/ManualCreationModal';

import { StyledCol, Wrapper } from './Dashboard/styled';
import { getHostsCountInWs, getVulnsCountInWs } from 'Screens/Contextualization/Faraday/actions/Actions';
import { selectIsFetching, selectReloadVulnerabilities, selectTools } from 'store/Dashboard/selectors';
import { closeModal, openModal } from 'store/modals/actions';
import { MODAL_CANT_CREATE } from 'store/modals/modals';
import selectModal from 'store/modals/selectors';
import WarningModal from 'Screens/Contextualization/ManageEditCreate/components/WarningModal';
import { CLOSED_FILTERS, CONFIRMED_FLAG_FILTERS } from 'store/Filters/constants';
import { isFilteringBy } from 'store/Filters/selectors';

const DashboardLayout = () => {
  const dispatch = useDispatch();
  const tools = useSelector(selectTools);
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const reloadVulnerabilities = useSelector(selectReloadVulnerabilities);
  const totalVulns = useSelector(selectAllVulnsCount);
  const isFetching = useSelector(selectIsFetching);

  const assetsCount = useSelector(selectAllHostsCount);
  const noAssets = assetsCount === 0;
  const showModalCantCreate = useSelector((state) => selectModal(state, MODAL_CANT_CREATE));

  const { confirmed } = CONFIRMED_FLAG_FILTERS;
  const { closed } = CLOSED_FILTERS;

  const isFilteringByConfirm = useSelector((state) => isFilteringBy(state, 'vulns', confirmed));
  const isFilteringByClosed = useSelector((state) => isFilteringBy(state, 'vulns', closed));

  useEffect(() => {
    if (!isFilteringByConfirm && !isFilteringByClosed) {
      dispatch(getSummary());
    }
  }, [dispatch, workspaceSelected, isFilteringByConfirm, isFilteringByClosed]);

  useEffect(() => {
    dispatch(getVulnsCount());
    dispatch(getTopHosts());
    dispatch(getVulnsCountInWs());
  }, [workspaceSelected, reloadVulnerabilities, dispatch]);

  useEffect(() => {
    dispatch(getHostsCountInWs());
  }, [workspaceSelected, dispatch]);

  const [showModalUploadVulns, toggleModalUploadVulns] = useState(false);
  const [showModalCreateVuln, toggleModalCreateVuln] = useState(false);

  const createManualVulns = () => {
    if (noAssets) dispatch(openModal(MODAL_CANT_CREATE));
    else toggleModalCreateVuln(true);
  };

  useEffect(() => () => {
    dispatch(closeModal(MODAL_CANT_CREATE));
  }, [dispatch]);

  if ((totalVulns === 0) && !isFetching) {
    return (
      <>
        <EmptyVulns image={ emptyFeed } toggleModalUploadVulns={ () => toggleModalUploadVulns(true) } toggleModalCreateVuln={ createManualVulns } />
        <ImportFileCreationModal show={ showModalUploadVulns } handleClose={ () => toggleModalUploadVulns(false) } />
        { showModalCreateVuln && <ManualCreationModal handleClose={ () => toggleModalCreateVuln(false) } /> }
        { showModalCantCreate && <WarningModal entity="Vulnerability" /> }
      </>
    );
  }

  return (
    <Wrapper>
      <StyledCol>
        { tools.filter((t) => t.render).length > 0 ? <Layout tools={ tools } /> : null }
      </StyledCol>
    </Wrapper>
  );
};

export default DashboardLayout;
