import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectReadOnly } from 'store/Faraday/selectors';
import IconButton from 'Common/Components/IconButton';
import { Trash } from 'Screens/Contextualization/Manage/components/CustomActionBar/styled';
import { showModalDelete } from 'store/Contextualization/AssetDetail/actions';
import { selectDetail, selectSelected } from 'store/Contextualization/AssetDetail/selectors';
import ModalWarning from 'Common/Components/ModalWarning';

const DeleteServiceButton = ({ hasServices }) => {
  const dispatch = useDispatch();
  const [showWarning, setShowWarning] = useState(false);
  const servicesSelected = useSelector((state) => selectSelected('services', state));
  const readOnly = useSelector(selectReadOnly);
  const isServiceDetailVisible = useSelector((state) => selectDetail('services', state));
  const canUpdate = !readOnly && !isServiceDetailVisible && hasServices;
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'services' });

  const handleClick = () => {
    if (canUpdate) {
      if (servicesSelected.length > 0) dispatch(showModalDelete('services'));
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <Trash /> } title="Delete" onClick={ handleClick } disabled={ !canUpdate } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Delete" description={ message } /> }
    </>
  );
};

export default DeleteServiceButton;
