import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectAllHostsCount, selectReadOnly } from 'store/Faraday/selectors';
import AddButton from 'Common/Components/AddButton';
import { openModal } from 'store/modals/actions';
import { MODAL_CANT_CREATE, MODAL_CREATE_SERVICE } from 'store/modals/modals';

const CreateActions = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const addText = intl.formatMessage({ id: 'service.addService' });
  const readOnly = useSelector(selectReadOnly);
  const canUpdate = !readOnly;
  const assetsCount = useSelector(selectAllHostsCount);
  const noAssets = assetsCount === 0;

  const handleShow = () => {
    if (noAssets) dispatch(openModal(MODAL_CANT_CREATE));
    else if (canUpdate) dispatch(openModal(MODAL_CREATE_SERVICE));
  };

  return (
    <AddButton
      onClick={ handleShow }
      title={ addText }
      text={ addText }
      disabled={ readOnly }
    />
  );
};

export default CreateActions;
