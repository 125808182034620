import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setPageNumberVulnAssets } from 'store/Contextualization/AssetDetail/actions';
import { selectGroupByField, selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import { Wrapper, LeftGroup, RightGroup, Separator, Group } from 'Common/Components/Contextualization/CustomActionBar/styled';
import Pagination from 'Common/Components/Pagination';
import CreateActions from './components/CreateActions';
import EditButton from './components/EditButton';
import TagButton from 'Screens/Contextualization/Manage/components/CustomActionBar/components/TagButton';
import CopyLink from './components/CopyLink';
import CopyApiLink from './components/CopyApiLink';
import DeleteButton from './components/DeleteButton';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';
import DuplicateButton from 'Screens/Contextualization/Manage/components/CustomActionBar/components/DuplicateButton';
import RefreshButton from '../RefreshButton';
import Severity from 'Screens/Contextualization/Manage/components/CustomActionBar/components/Severity';
import Status from 'Screens/Contextualization/Manage/components/CustomActionBar/components/Status';
import AddNewEvidence from './components/AddNewEvidence';
import SaveAsTemplate from 'Screens/Contextualization/Manage/components/CustomActionBar/components/SaveAsTemplate';
import ApplyTemplate from 'Screens/Contextualization/Manage/components/CustomActionBar/components/ApplyTemplate';
import AddRemoveColumnsButton from 'Screens/Contextualization/Manage/components/CustomActionBar/components/AddRemoveColumnsButton';
import FilterSideBarIcon from 'Screens/Contextualization/Manage/components/CustomActionBar/components/FilterSidebarIcon';
import { selectCount, selectSelected, selectShowDeleteConfirmation } from 'store/Contextualization/AssetDetail/selectors';
import { selectVulnsAssetFields } from 'store/Preferences/vulnsAsset/selectors';

const ActionBar = ({ setFF, entity }) => {
  const dispatch = useDispatch();
  const vulnsCount = useSelector((state) => selectCount('vulns', state));
  const page = useSelector((state) => selectPage('vulnsAssets', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('vulnsAssets', state));
  const hasVulns = vulnsCount > 0;
  const onPrev = () => dispatch(setPageNumberVulnAssets(page - 1));
  const onNext = () => dispatch(setPageNumberVulnAssets(page + 1));
  const vulnsSelected = useSelector((state) => selectSelected('vulns', state));
  const isGrouping = useSelector((state) => selectGroupByField('vulnsAssets', state));
  const fields = useSelector(selectVulnsAssetFields);
  const showDeleteConfirmation = useSelector((state) => selectShowDeleteConfirmation('vulns', state));

  return (
    <Wrapper>
      { showDeleteConfirmation ? <DeleteConfirmationModal /> : null }
      <LeftGroup>
        <CreateActions />
        <Separator />
        <Group>
          <EditButton hasVulns={ hasVulns } />
          <TagButton setFF={ setFF } hasItems={ hasVulns } />
          <CopyLink hasVulns={ hasVulns } />
          <CopyApiLink hasVulns={ hasVulns } />
          <Severity hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } entity={ entity } />
          <Status hasVulns={ hasVulns } groupBy={ isGrouping } vulnsSelected={ vulnsSelected } entity={ entity } />
          <AddNewEvidence hasVulns={ hasVulns } />
          <SaveAsTemplate hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } entity={ entity } />
          <ApplyTemplate hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } />
          <DeleteButton hasVulns={ hasVulns } />
          <DuplicateButton setFF={ setFF } />
        </Group>
      </LeftGroup>

      <RightGroup>
        { <Pagination offset={ hasVulns ? page : null } limit={ rowsPerPage } total={ vulnsCount } onPrev={ onPrev } onNext={ onNext } />}
        { hasVulns && <Separator /> }
        <Group>
          <RefreshButton />
          <FilterSideBarIcon entity="vulnsAssets" />
          <AddRemoveColumnsButton fields={ fields } />
        </Group>
      </RightGroup>
    </Wrapper>
  );
};

export default ActionBar;
