import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectReadOnly } from 'store/Faraday/selectors';
import IconButton from 'Common/Components/IconButton';
import { Trash } from '../../styled';
import { showVulnModalDelete } from 'store/Manage/actions';
import { selectShowDetail, selectVulnsSelected } from 'store/Manage/selectors';
import ModalWarning from 'Common/Components/ModalWarning';

const DeleteButton = ({ hasVulns }) => {
  const dispatch = useDispatch();
  const [showWarning, setShowWarning] = useState(false);
  const readOnly = useSelector(selectReadOnly);
  const vulnsSelected = useSelector(selectVulnsSelected);
  const isVulnDetailVisible = useSelector(selectShowDetail);
  const canUpdate = !readOnly && !isVulnDetailVisible && hasVulns;
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'vulnerabilities' });

  const handleClick = () => {
    if (canUpdate) {
      if (vulnsSelected.length > 0) dispatch(showVulnModalDelete());
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <Trash /> } title="Delete" onClick={ handleClick } disabled={ !canUpdate } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Delete" description={ message } /> }
    </>
  );
};

export default DeleteButton;
