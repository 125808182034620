import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CONFIRMED_FLAG_FILTERS } from 'store/Filters/constants';
import { addFilter, removeFilter } from 'store/Filters/actions';
import { newGetVulns } from 'store/Manage/filterActions';
import FlagIcon from 'Common/Components/FlagIcon';
import { Wrapper, IconWrapper } from './styled';
import { selectPathname } from 'store/Router/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { getSelectedEntity, isFilteringBy } from 'store/Filters/selectors';
import { selectCurrentHost } from 'store/Host/selectors';
import { getVulns } from 'store/Contextualization/AssetDetail/actions';

const VulnFlagFilter = ({ theme, setTheme, themeList }) => {
  const dispatch = useDispatch();
  const { confirmed, notConfirmed } = CONFIRMED_FLAG_FILTERS;
  const pathname = useSelector(selectPathname);
  const currentWorkspace = useSelector(selectCurrentWorkspace);
  const host = useSelector(selectCurrentHost);
  const entity = useSelector(getSelectedEntity);
  const isFilteringByConfirm = useSelector((state) => isFilteringBy(state, entity, confirmed));
  const isFilteringByNotConfirm = useSelector((state) => isFilteringBy(state, entity, notConfirmed));
  const isFilteringByConfirmAndNotConfirmed = isFilteringByConfirm && isFilteringByNotConfirm;
  const allowedToSetFilter = pathname.startsWith('/feed/') ||
    pathname.startsWith('/manage/') ||
    pathname.startsWith(`/host/${currentWorkspace}/${host.id}/vulns`);

  useEffect(() => {
    if (isFilteringByConfirmAndNotConfirmed) setTheme(0);
    else if (isFilteringByConfirm) setTheme(1);
    else if (isFilteringByNotConfirm) setTheme(2);
    else setTheme(0);
  }, [isFilteringByConfirm, isFilteringByNotConfirm, isFilteringByConfirmAndNotConfirmed, setTheme]);

  const handleClick = () => {
    if (theme === 0) {
      setTheme(1);
      if (allowedToSetFilter) {
        dispatch(removeFilter('vulns', notConfirmed));
        dispatch(addFilter('vulns', confirmed));
        dispatch(removeFilter('vulnsAssets', notConfirmed));
        dispatch(addFilter('vulnsAssets', confirmed));
      }
    } else if (theme === 1) {
      setTheme(2);
      if (allowedToSetFilter) {
        dispatch(removeFilter('vulns', confirmed));
        dispatch(addFilter('vulns', notConfirmed));
        dispatch(removeFilter('vulnsAssets', confirmed));
        dispatch(addFilter('vulnsAssets', notConfirmed));
      }
    } else {
      setTheme(0);
      if (allowedToSetFilter) {
        dispatch(removeFilter('vulns', confirmed));
        dispatch(removeFilter('vulns', notConfirmed));
        dispatch(removeFilter('vulnsAssets', confirmed));
        dispatch(removeFilter('vulnsAssets', notConfirmed));
      }
    }
    if (pathname.startsWith(`/manage/${currentWorkspace}`)) dispatch(newGetVulns());
    if (pathname.startsWith(`/host/${currentWorkspace}/${host.id}/vulns`)) dispatch(getVulns(host.ip));
  };

  const getLabel = () => {
    if (theme === 0) return 'All vulnerabilities';
    if (theme === 1) return 'Confirmed vulnerabilities';
    if (theme === 2) return 'Not confirmed vulnerabilities';
    return '';
  };

  return (
    <Wrapper>
      <IconWrapper onClick={ handleClick } title={ getLabel() }>
        <FlagIcon theme={ themeList[theme] } />
      </IconWrapper>
    </Wrapper>
  );
};

export default VulnFlagFilter;
