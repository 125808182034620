import * as types from './types';

const initialState = {
  redirectPathname: '',
  redirectSearch: '',
  redirectSaveHistory: false,
  redirectHasPendingChanges: false,
  lastUrlVisitedInsideWs: '/workspaces'
};

function global (state = initialState, action) {
  switch (action.type) {
    case types.REDIRECT_START: {
      return {
        ...state,
        redirectPathname: action.pathname,
        redirectSearch: action.search,
        redirectSaveHistory: action.saveHistory
      };
    }
    case types.REDIRECT_CLEAR: {
      return {
        ...state,
        redirectPathname: initialState.redirectPathname,
        redirectSearch: initialState.redirectSearch,
        redirectSaveHistory: initialState.redirectSaveHistory
      };
    }
    case types.SET_HAS_PENDING_CHANGES: {
      return {
        ...state,
        redirectHasPendingChanges: action.hasPendingChanges
      };
    }
    case types.EXPAND_COLLAPSE: {
      const defaultFields = state.tabs && state.tabs[action.tab] ? state.tabs[action.tab] : null;
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [action.tab]: {
            ...defaultFields,
            ...action.fields
          }
        }
      };
    }
    case types.SET_LAST_URL_VISITED_IN_WS: {
      return {
        ...state,
        lastUrlVisitedInsideWs: action.url,
        workspaceSelected: action.workspace
      };
    }
    default:
      return state;
  }
}

export default global;
