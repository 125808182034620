import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { selectCurrentWorkspace, selectIsAdmin } from 'store/Faraday/selectors';
import useClickOutside from 'Hooks/useClickOutside';
import { redirect } from 'store/Router/actions';
import {
  Wrapper, Toggle, Menu, Item, AssetSelectedIp,
  ArrowDown, AssetOptionIp, ActionsContainer,
  ViewAll, CreateNew, NoItems, Items, CloseIconWrapper,
  CloseIcon, Icons
} from './styled';
import { selectCurrentHost, selectHostList } from 'store/Host/selectors';
import { toggleModalEditCreate, selectRow, unSelectAll } from 'store/Host/actions';
import { selectLocation } from 'store/Router/selectors';
import get from 'lodash/get';
import { getAssetServices, getVulns, redirectToHost } from 'store/Contextualization/AssetDetail/actions';
import { selectedForWorking } from 'store/Contextualization/AssetDetail/selectors';

const AssetSelector = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  const hostSelected = useSelector(selectCurrentHost);
  const hostIp = get(hostSelected, 'ip', '');
  const hostId = get(hostSelected, 'id', '');
  const assets = useSelector(selectHostList);
  const noAssets = assets.length === 0;
  const showNoItem = (assets.length === 1 && assets[0].ip === hostIp);
  const dropdownRef = useRef();
  useClickOutside(dropdownRef, () => setShowDropdown(false));
  const isAdmin = useSelector(selectIsAdmin);
  const location = useSelector(selectLocation);
  const currentWorkspace = useSelector(selectCurrentWorkspace);
  const assetService = useSelector((state) => selectedForWorking('services', state));
  const assetVuln = useSelector((state) => selectedForWorking('vulns', state));

  const isInAHost = (location.pathname === `/host/${currentWorkspace}/${hostId}/vulns`) ||
  (location.pathname === `/host/${currentWorkspace}/${hostId}/services`) ||
  (location.pathname === `/host/${currentWorkspace}/${hostId}/vulns/${assetVuln._id}`) ||
  (location.pathname === `/host/${currentWorkspace}/${hostId}/services/${assetService.id}`);

  const showAssetSelector = assets && isInAHost;

  const getItems = () => {
    const handleClick = (e, asset) => {
      setShowDropdown(false);
      if (location.pathname.includes('/vulns')) {
        dispatch(redirect(`/host/${currentWorkspace}/${asset.id}/vulns`));
        dispatch(getVulns(asset.ip));
      } else {
        dispatch(redirect(`/host/${currentWorkspace}/${asset.id}/services`));
        dispatch(getAssetServices(asset.id));
      }
      dispatch(unSelectAll());
      dispatch(selectRow(e, asset));
    };
    if (showNoItem) {
      return (
        <NoItems><FormattedMessage id="assetSelector.noOther" /></NoItems>
      );
    }
    return assets.map((asset, index) => {
      if (asset.ip !== hostIp) {
        return (
          <Item id={ `asset-selector-${asset.ip}` } onClick={ (e) => { handleClick(e, asset); } } key={ asset.id } lastelement={ index + 1 === assets.length }>
            <AssetOptionIp>{asset.ip}</AssetOptionIp>
            {/* <AssetVulns>{asset.vulns}</AssetVulns> */}
          </Item>
        );
      } return null;
    });
  };

  const handleToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(redirectToHost());
    setShowDropdown(false);
  };

  return showAssetSelector && (
    <Wrapper ref={ dropdownRef } data-tour="Asset Selector">
      <Toggle onClick={ handleToggle }>
          <AssetSelectedIp id={ `asset-selector-selected-${hostIp}` } title={ hostIp }>
            { hostIp }
          </AssetSelectedIp>
          <Icons>
            <CloseIconWrapper onClick={ handleClose }>
              <CloseIcon />
            </CloseIconWrapper>
            <ArrowDown />
          </Icons>
      </Toggle>
      { assets.length >= 0 && showDropdown && (
        <Menu>
          <Items>
            {getItems()}
          </Items>
          <ActionsContainer>
            {!noAssets && <ViewAll onClick={ () => { setShowDropdown(false); dispatch(redirectToHost()); } }><FormattedMessage id="workspaces.contextMenu.viewAll" /></ViewAll>}
            { isAdmin && <CreateNew onClick={ () => { setShowDropdown(false); dispatch(toggleModalEditCreate(true)); } }><FormattedMessage id="workspaces.contextMenu.createNew" /></CreateNew> }
          </ActionsContainer>
        </Menu>
      ) }
    </Wrapper>
  );
};

export default AssetSelector;
