/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'Screens/Contextualization/Host/components/Table';
import ActionBar from './components/ActionBar';
import HostModalEditCreate from 'Screens/Contextualization/HostEditCreate/components/HostModalEditCreate';
import { bulkUpdateAssets, getData } from 'store/Host/actions';
import {
  hideHostDetail,
  showHostDetailById
} from 'store/Contextualization/AssetDetail/actions';
import { connect } from 'react-redux';
import HostDetail from 'Screens/Contextualization/Host/components/HostDetail';
import { selectHostCount, selectHostsSelected, selectIsFetching, selectModalBulkUpdateValue, selectSelectAllHosts, selectShowHostLeftFilters } from 'store/Host/selectors';
import get from 'lodash/get';
import FilterSideBar from 'Screens/Contextualization/Manage/components/FilterSideBar';
import { Wrapper, TableContainer } from './styled';
import { MODAL_UPGRADE_LICENSE_ACTION_BAR } from 'store/modals/modals';
import ReduxModal from 'Common/Components/ReduxModal';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import { getVulnsCountInWs } from 'Screens/Contextualization/Faraday/actions/Actions';
import { selectPathname } from 'store/Router/selectors';
import AssetsContextMenu from './components/Table/ContextMenu';
import BulkUpdateModal from './components/Table/components/BulkUpdateModal';
import BulkUpdateConfirmationDialog from '../Manage/components/ManageTable/components/BulkUpdateConfirmationDialog';
import SelectAll from './components/SelectAll';

class HostContext extends Component {
  constructor (props) {
    super(props);
    this.state = {
      addRemoveColumnsOpened: false,
      FFImage: null,
      FFDescription: ''
    };
    this.actionBar = React.createRef();
    this.getTableContainerHeight = this.getTableContainerHeight.bind(this);
    this.setFF = this.setFF.bind(this);
  }

  handleParamId () {
    const { pathname } = this.props;
    const id = get(this, 'props.params.id', 0);
    const currentWorkspace = get(this, 'props.params.ws', '');
    const isUrlInHostDetail = pathname === `/host/${currentWorkspace}/${id}`;
    if (isUrlInHostDetail && parseInt(id, 10) > 0) this.props.showHostDetailById(id);
    else this.props.hideHostDetail();
  }

  componentDidMount () {
    this.handleParamId();
  }

  componentWillUnmount () {
    this.handleParamId();
  }

  componentDidUpdate (prevProps) {
    const { pathname } = this.props;
    if (pathname !== prevProps.location.pathname) {
      this.handleParamId();
      this.props.getVulnsCount();
    }
  }

  getTableContainerHeight () {
    const actionBarHeight = get(this.actionBar, 'current.clientHeight', 34);
    const tableContainerTopMargin = 0;
    return `calc(100% - ${actionBarHeight + tableContainerTopMargin}px)`;
  }

  toggleAddColumns () {
    this.setState((prevState) => ({
      addRemoveColumnsOpened: !prevState.addRemoveColumnsOpened
    }));
  }

  setFF (image, description) {
    this.setState({ FFImage: image, FFDescription: description });
  }

  render () {
    const {
      showLeftFilters, hostsSelected, count, isFetching, bulkUpdateValue, selectAll
    } = this.props;

    return (
      <Wrapper>
        <div ref={ this.actionBar }>
          <ActionBar setFF={ this.setFF } />
          <ReduxModal id={ MODAL_UPGRADE_LICENSE_ACTION_BAR }>
          <UpgradeLicense id={ MODAL_UPGRADE_LICENSE_ACTION_BAR } image={ this.state.FFImage } descriptionIntlId={ this.state.FFDescription } />
          </ReduxModal>
        </div>
        <SelectAll />
        <TableContainer height={ this.getTableContainerHeight() }>
          <Table showLeftFilters={ showLeftFilters } />
          <FilterSideBar entity="assets" />
          <HostDetail />
          <AssetsContextMenu />
          <BulkUpdateModal />
          <BulkUpdateConfirmationDialog
            selected={ hostsSelected }
            count={ count }
            isSelectAll={ selectAll }
            isFetching={ isFetching }
            bulkUpdateValue={ bulkUpdateValue }
            bulkUpdateAction={ bulkUpdateAssets }
            title="Assets"
          />
        </TableContainer>
        <HostModalEditCreate />

      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  showLeftFilters: selectShowHostLeftFilters(state),
  pathname: selectPathname(state),
  hostsSelected: selectHostsSelected(state),
  count: selectHostCount(state),
  isFetching: selectIsFetching(state),
  bulkUpdateValue: selectModalBulkUpdateValue(state),
  selectAll: selectSelectAllHosts(state)
});

const mapDispatchToProps = (dispatch) => ({
  showHostDetailById: (host) => {
    dispatch(showHostDetailById(host));
  },
  hideHostDetail: () => {
    dispatch(hideHostDetail());
  },
  getData: () => {
    dispatch(getData());
  },
  getVulnsCount: () => {
    dispatch(getVulnsCountInWs());
  }
});

HostContext.propTypes = {
  showHostDetailById: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(HostContext);
