import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import * as p from 'store/Global/types';

// eslint-disable-next-line import/prefer-default-export
export const toggleExpandCollapse = (tab, fields) => {
  return async (dispatch) => {
    dispatch({ type: p.EXPAND_COLLAPSE, tab, fields });
  };
};

export const setLastUrlVisitedInsideWs = (url) => {
  return async (dispatch, getState) => {
    const workspace = selectCurrentWorkspace(getState());
    dispatch({ type: p.SET_LAST_URL_VISITED_IN_WS, url, workspace });
  };
};
