import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ContextMenuButton from 'Common/Components/ContextMenuButton';
import IconButton from 'Common/Components/IconButton';
import EditColumnContext from 'Common/Components/EditColumnContext';
import { resetDefault, setFieldVisibility, setNewOrderColumns } from 'store/Preferences/service/actions';
import { EditColumns } from 'Screens/Contextualization/Manage/components/CustomActionBar/styled';

const AddRemoveColumnsButton = ({ fields }) => {
  const [showContext, toggleContext] = useState(false);
  const dispatch = useDispatch();

  const addRemoveColumns = (
    <EditColumnContext
      trigger="action-button-column"
      fields={ fields }
      toggle={ () => toggleContext(!showContext) }
      setFieldVisibility={ (fieldObj, value) => dispatch(setFieldVisibility(fieldObj, value)) }
      setNewOrderColumns={ (columns) => dispatch(setNewOrderColumns(columns)) }
      resetDefault={ () => dispatch(resetDefault()) }
    />
  );

  return (
    <ContextMenuButton
      opened={ showContext }
      menu={ addRemoveColumns }
      trigger="action-button-column"
      display
      icon={ <IconButton icon={ <EditColumns /> } title="More" /> }
    />
  );
};

export default AddRemoveColumnsButton;
