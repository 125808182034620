import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectReadOnly } from 'store/Faraday/selectors';
import { useIntl } from 'react-intl';
import IconButton from 'Common/Components/IconButton';
import { Trash } from '../../styled';
import { showServiceModalDelete } from 'store/Services/actions';
import { selectServicesSelected } from 'store/Services/selectors';
import ModalWarning from 'Common/Components/ModalWarning';

const DeleteServiceButton = ({ hasServices }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [showWarning, setShowWarning] = useState(false);
  const readOnly = useSelector(selectReadOnly);
  const servicesSelected = useSelector(selectServicesSelected);
  const canUpdate = !readOnly && hasServices;
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'services' });

  const handleClick = () => {
    if (canUpdate) {
      if (servicesSelected.length > 0) dispatch(showServiceModalDelete());
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);
  return (
    <>
      <IconButton icon={ <Trash /> } title="Delete" onClick={ handleClick } disabled={ readOnly || !hasServices } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Delete" description={ message } /> }
    </>
  );
};

export default DeleteServiceButton;
