import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import copyToClipboard from 'utils/clipboard';
import { selectSelected } from 'store/Contextualization/AssetDetail/selectors';
import IconButton from 'Common/Components/IconButton';
import CopyLinkIcon from './styled';
import ModalWarning from 'Common/Components/ModalWarning';

const CopyLink = ({ hasVulns }) => {
  const [showWarning, setShowWarning] = useState(false);
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'manage.detail.tab.copyLink' });
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'vulnerability' });
  const vulnsSelected = useSelector((state) => selectSelected('vulns', state));
  const vulnId = get(vulnsSelected, '[0]._id', 0);
  const link = get(window, 'location.href', '');

  const copyLink = () => {
    if (hasVulns) {
      if (vulnsSelected.length === 1) copyToClipboard(`${link}/${vulnId}`);
      else setShowWarning(true);
    }
  };
  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <CopyLinkIcon /> } title={ title } onClick={ copyLink } disabled={ !hasVulns } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title={ title } description={ message } /> }
    </>
  );
};

export default CopyLink;
