import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectReadOnly } from 'store/Faraday/selectors';
import AddButton from 'Common/Components/AddButton';

const CreateActions = ({ setVisibilityCreateModal }) => {
  const intl = useIntl();
  const addText = intl.formatMessage({ id: 'host.create' });
  const readOnly = useSelector(selectReadOnly);
  const canUpdate = !readOnly;
  const handleShow = () => {
    if (canUpdate) setVisibilityCreateModal(true);
  };

  return (
    <AddButton
      onClick={ handleShow }
      title={ addText }
      text={ addText }
      disabled={ readOnly }
    />
  );
};

export default CreateActions;
