import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CreateActions from 'Screens/Contextualization/Services/components/ActionBar/components/CreateActions';
import { Wrapper, LeftGroup, RightGroup, Separator, Group } from 'Common/Components/Contextualization/CustomActionBar/styled';
import DeleteServiceButton from './components/DeleteServiceButton';
import EditServiceButton from './components/EditServiceButton';
import Pagination from 'Common/Components/Pagination';
import { setPageNumberServices } from 'store/Services/actions';
import { selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';
import RefreshButton from './components/RefreshButton';
import { selectServicesCount, selectShowDeleteConfirmation } from 'store/Services/selectors';
import CopyLink from './components/CopyLink';
import CopyApiLink from './components/CopyApiLink';
import AddRemoveColumnsButton from './components/AddRemoveColumnsButton';
import TagButton from 'Screens/Contextualization/Manage/components/CustomActionBar/components/TagButton';
import { selectFields } from 'store/Preferences/service/selectors';

const ActionBar = ({ setFF }) => {
  const dispatch = useDispatch();
  const servicesCount = useSelector(selectServicesCount);
  const page = useSelector((state) => selectPage('services', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('services', state));
  const onPrev = () => dispatch(setPageNumberServices(page - 1));
  const onNext = () => dispatch(setPageNumberServices(page + 1));
  const hasServices = servicesCount > 0;
  const fields = useSelector(selectFields);
  const showDeleteConfirmation = useSelector(selectShowDeleteConfirmation);

  return (
    <Wrapper>
      { showDeleteConfirmation ? <DeleteConfirmationModal /> : null }
      <LeftGroup>
        <CreateActions />
        <Separator />
        <Group>
          <EditServiceButton hasServices={ hasServices } />
          <TagButton setFF={ setFF } hasItems={ hasServices } />
          <CopyLink hasServices={ hasServices } />
          <CopyApiLink hasServices={ hasServices } />
          <DeleteServiceButton hasServices={ hasServices } />
        </Group>
      </LeftGroup>

      <RightGroup>
        { hasServices &&
          <>
            <Pagination offset={ page } limit={ rowsPerPage } total={ servicesCount } onPrev={ onPrev } onNext={ onNext } />
            <Separator />
          </>
        }
        <Group>
          <RefreshButton />
          <AddRemoveColumnsButton fields={ fields } />
        </Group>
      </RightGroup>
    </Wrapper>
  );
};

export default ActionBar;
