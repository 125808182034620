import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import IconButton from 'Common/Components/IconButton';
import { selectSelected } from 'store/Contextualization/AssetDetail/selectors';
import EvidenceIcon from './styled';
import { redirectToManageDetail } from 'store/Contextualization/AssetDetail/actions';
import ModalWarning from 'Common/Components/ModalWarning';
import { selectReadOnly } from 'store/Faraday/selectors';

const AddNewEvidence = ({ hasVulns }) => {
  const [showWarning, setShowWarning] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'manage.cm.addNewEvidence' });
  const vulnsSelected = useSelector((state) => selectSelected('vulns', state));
  const selectedVulnID = get(vulnsSelected, '[0]._id', 0);
  const readOnly = useSelector(selectReadOnly);
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'vulnerability' });
  const canUpdate = !readOnly && hasVulns;

  const openEvidence = () => {
    if (canUpdate) {
      if (vulnsSelected.length === 1) dispatch(redirectToManageDetail(selectedVulnID, 'evidence'));
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <EvidenceIcon /> } title={ title } onClick={ openEvidence } disabled={ !canUpdate } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title={ title } description={ message } /> }
    </>
  );
};

export default AddNewEvidence;
