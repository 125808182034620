import styled from 'styled-components';
import colors from 'Styles/colors';
import { FaChevronDown } from 'react-icons/fa';
import { Title } from '../styled';
import { ellipsisText } from 'Styles/styles';
import { ReactComponent as TrashIcon } from 'Images/icon-action-bar-trash.svg';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const InputWrapper = styled.div`
  height: 34px;
  position: relative;
`;

export const StyledTitle = styled(Title)`
  ${({ required }) => required && `
    position: relative;
    &:after {
      content: '*';
      color: ${colors.redPink};
      position: absolute;
      top: 3px;
      margin-left: 3px;
    }
  `}  
`;
StyledTitle.displayName = 'StyledTitle';

export const InputComp = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  border-left: 12px solid ${colors.softBlue};
  box-shadow: 0 1px 0 0 ${colors.grey30};
  background-color: ${colors.iceBlue};
  padding-left: 15px;
  padding-right: 42px;
  font-weight: 300 !important;
  margin-bottom: 2px;

  &.full{
    background-color: ${colors.white}; 
  }

  &.responsive {
    width: 100%;
  }

  &::placeholder {
    color: ${colors.grey17};
    font-size: 13.5px;
    font-weight: 300;
  }

  &:hover{
    border-style: solid;
    border-color: ${colors.grey17};
    border-width: 1px 1px 0px 1px; 
    border-left: 12px solid ${colors.softBlue};
  }

  &:focus{
    background-color: ${colors.white};

    &::placeholder {
      color: transparent;
    }
  }

  ${({ disabled }) => disabled && 'cursor: not-allowed'};
`;
InputComp.displayName = 'InputComp';

export const ChevronDown = styled(FaChevronDown)`
  position: absolute;
  right: 14px;
  top: 14px;
  cursor: pointer;
  color: #515a65;
  font-size: 11px;
`;
ChevronDown.disabled = 'ChevronDown';

export const ListData = styled.div` 
  background-color: ${colors.white};
  box-shadow: 0 2px 50px 0 ${colors.iceBlue};
  overflow-y: auto;
  margin-top: 8px;
  max-height: 102px;
`;
ListData.displayName = 'ListData';

export const DataItem = styled.div`    
  cursor: pointer;
  padding: 0 14px;
  height: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (props.isOdd ? `${colors.iceBlue}` : '')};
`;
DataItem.displayName = 'DataItem';

export const DataItemWrapper = styled.div`
  display: flex;
  width: 100%;
`;
DataItemWrapper.displayName = 'DataItemWrapper';

export const Placeholder = styled.p`
  text-align: left;
  font-size: 13.5px;
  line-height: 2.52;
  margin-right: auto;
  color: ${colors.darkBlueGrey};
  ${ellipsisText}
`;
Placeholder.displayName = 'Placeholder';

export const Text = styled(Placeholder)`
  width: 90px;
`;
Text.displayName = 'Text';

export const TrashWrapper = styled.div`
  margin-left: auto;
  padding-left: 20px;
`;
TrashWrapper.displayName = 'TrashWrapper';

export const Trash = styled(TrashIcon)` 
  width: 16px;
  height: 17px;
  align-self: flex-end;
  g > path { 
    fill: ${colors.blueGrey};
  }

`;
Trash.displayName = 'Trash';
