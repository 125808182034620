import React from 'react';
import head from 'lodash/head';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { selectDetail } from 'store/Contextualization/AssetDetail/selectors';
import DateFormatter from 'Common/Components/DateFormatter';
import { FormattedMessage } from 'react-intl';
import {
  Wrapper, FieldContainer, FieldName, FieldValue
} from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/HostData/styled';

// eslint-disable-next-line import/prefer-default-export
export const HostData = () => (
  <Wrapper>
    <Target />
    <Hostname />
    <Date />
  </Wrapper>
);

const Target = () => {
  const vuln = useSelector((state) => selectDetail('vulns', state));
  const target = get(vuln, 'target', '');

  return (
    <FieldContainer>
      <FieldName>
        <FormattedMessage id="manage.detail.tab.general.hostData.host" />
      </FieldName>
      <FieldValue>{target}</FieldValue>
    </FieldContainer>
  );
};

const Hostname = () => {
  const vuln = useSelector((state) => selectDetail('vulns', state));
  const hostnames = get(vuln, 'hostnames', []).filter((h) => !isEmpty(h));
  const hostname = head(hostnames);

  if (!hostname) return null;
  return (
    <FieldContainer>
      <FieldName>
        <FormattedMessage id="manage.detail.tab.general.hostData.hostname" />
      </FieldName>
      <FieldValue>{hostname}</FieldValue>
    </FieldContainer>
  );
};

const Date = () => {
  const vuln = useSelector((state) => selectDetail('vulns', state));
  const createTime = get(vuln, 'metadata.create_time', '');

  return (
    <FieldContainer>
      <FieldName>
        <FormattedMessage id="manage.detail.tab.general.hostData.date" />
      </FieldName>
      <FieldValue><DateFormatter date={ createTime } /></FieldValue>
    </FieldContainer>
  );
};
