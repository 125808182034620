import React from 'react';
import { DashboardTour } from 'Common/Components/Contextualization/Tour';
import DashboardLayout from '../DashboardLayout';

const DashboardContext = () => (
  <>
    <DashboardLayout />
    <DashboardTour />
  </>
);

export default DashboardContext;
