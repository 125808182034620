import React, { useState } from 'react';
import { Wrapper, Count } from './styled';
import { useSelector } from 'react-redux';
import VulnFlagFilter from 'Common/Components/Contextualization/VulnFlagFilter';
import { selectAllVulnsCount, selectConfirmedVulnsCount, selectUnconfirmedVulnsCount } from 'store/Faraday/selectors';
import { generalView } from 'store/Tabs/selectors';
import formatNumber from 'Common/Functions/FormatNumber';

const VulnsCounter = () => {
  const themeList = ['allVulns', 'confirmed', 'notConfirmed'];
  const [theme, setTheme] = useState(0);
  const allVulnsCount = useSelector(selectAllVulnsCount);
  const confirmedCount = useSelector(selectConfirmedVulnsCount);
  const unconfirmedCount = useSelector(selectUnconfirmedVulnsCount);
  const isInGeneralView = useSelector(generalView);

  const count = () => {
    if (theme === 0) return formatNumber(allVulnsCount);
    else if (theme === 1) return formatNumber(confirmedCount);
    else return formatNumber(unconfirmedCount);
  };

  return (isInGeneralView) && (
    <Wrapper>
      <Count>{ `${count()} vulnerabilities` }</Count>
      <VulnFlagFilter theme={ theme } setTheme={ setTheme } themeList={ themeList } />
    </Wrapper>
  );
};

export default VulnsCounter;
