/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { removeFiltersByKey } from 'store/Filters/actions';
import { useDispatch, useSelector } from 'react-redux';
import { newGetVulns } from 'store/Manage/filterActions';
import { getData } from 'store/Host/actions';
import { useLocation } from 'react-router-dom';
import { SEARCH_BY_ENTITIES, ENTITIES } from 'store/Filters/entitiesConstants';
import get from 'lodash/get';
import { isFilteringBy } from 'store/Filters/selectors';
import { getVulnsByAsset } from 'store/Contextualization/AssetDetail/actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { selectCurrentHost } from 'store/Host/selectors';
import defaultFilters from './defaultFilters';
import {
  Subtitle, Title, Flex, Clear, ExpandedIcon, CollapsedIcon, Content
} from '../styled';
import { SeverityFilter, CheckBoxFilter } from './filterTypes';

const QuickFilters = () => {
  const location = useLocation();
  const currentWorkspace = useSelector(selectCurrentWorkspace);
  const currentAsset = useSelector(selectCurrentHost);
  const assetId = get(currentAsset, '_id', 0);
  const currentLocationEntity = () => {
    if (location.pathname.includes(`host/${currentWorkspace}/${assetId}/vulns`)) return ENTITIES.vulnsAssets;
    else return SEARCH_BY_ENTITIES.find((e) => location.pathname.includes(e.pathname));
  };
  const entityName = get(currentLocationEntity(), 'name.value', 'vulns');

  return (
    <>
      <Subtitle>Quick Filters</Subtitle>
      {defaultFilters[entityName].map((filter) => <QuickFilterItem { ...filter } key={ JSON.stringify(filter) } entityName={ entityName } showClear={ filter.showClear } />)}
    </>
  );
};

const QuickFilterItem = ({
  title, options, type, filter, name, displayOptions, entityName, showClear
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [isExpanded, setIsExpanded] = useState(true);

  const anyFilterApplied = options.some((option) => (isFilteringBy(state, entityName, filter(option))));

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const handleClick = () => {
    if (showClear && anyFilterApplied) {
      dispatch(removeFiltersByKey(entityName, name));
      if (entityName === 'vulns') dispatch(newGetVulns());
      if (entityName === 'assets') dispatch(getData());
      if (entityName === 'vulnsAssets') dispatch(getVulnsByAsset());
    }
  };

  return (
    <>
      <Flex>
        <Title>{title}</Title>
        <Clear onClick={ handleClick }>Clear</Clear>
        { isExpanded ? <ExpandedIcon onClick={ toggleExpanded } /> : <CollapsedIcon onClick={ toggleExpanded } /> }
      </Flex>

      <Content vertical={ type === 'checkbox' } isExpanded={ isExpanded }>
        {type === 'severity' && (displayOptions.map((option, i) => <SeverityFilter key={ `severity_${option}` } entityName={ entityName } option={ option } filter={ filter(options[i]) } />))}
        {type === 'checkbox' && displayOptions.map((option, i) => <CheckBoxFilter key={ `checkboux_${option}` } entityName={ entityName } option={ option } filter={ filter(options[i]) } />)}
      </Content>
    </>
  );
};

export default QuickFilters;
