import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from 'Common/Components/IconButton';
import { RefreshIcon } from './styled';
import { getVulns } from 'store/Contextualization/AssetDetail/actions';
import { selectCurrentHost } from 'store/Host/selectors';
import { get } from 'lodash';

const RefreshButton = () => {
  const dispatch = useDispatch();
  const currentHost = useSelector(selectCurrentHost);
  const hostIp = get(currentHost, 'ip', '');
  const handleClick = () => (dispatch(getVulns(hostIp)));

  return (
    <IconButton icon={ <RefreshIcon /> } title="Refresh" onClick={ handleClick } />
  );
};

export default RefreshButton;
