import React from 'react';
import CustomFieldsTab from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/index';
import { Wrapper } from '../General/styled';

const CustomFields = () => (
  <Wrapper>
    <CustomFieldsTab />
  </Wrapper>
);

export default CustomFields;
