import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  enableDisable
} from 'Screens/Workspaces/actions/Actions';
import { selectIsFetching } from 'store/Workspace/selectors';
import ModalWrapper from 'Common/Components/ModalWrapper';
import {
  ModalTitle, Title, BodyText, ModalContent, ButtonsContainer,
  ConfirmButton, CancelButton, Warning, ModalBody
} from 'Common/Components/ModalConfirmation/styled';
import useKeyPress from 'Hooks/useKeyPress';

const DeactivateConfirmationModal = ({ onCloseCallback, show, ws }) => {
  const dispatch = useDispatch();
  const isFetching = useSelector(selectIsFetching);

  const handleSubmit = () => {
    dispatch(enableDisable(ws, true));
    onCloseCallback();
  };
  useKeyPress(() => {
    handleSubmit();
  }, ['Enter']);

  useKeyPress(() => {
    if (onCloseCallback) onCloseCallback();
  }, ['Escape']);
  if (!show) return null;
  return (
    <ModalWrapper>
      <ModalContent>
        <ModalTitle>
          <Warning />
          <Title>{'Deactivate Workspace'}</Title>
        </ModalTitle>
        <ModalBody>
          <BodyText>{'You’re deactivating a workspace.  Are you sure you want to proceed?'}</BodyText>
        </ModalBody>
        <ButtonsContainer>
          <CancelButton onClick={ onCloseCallback }>
            Cancel
          </CancelButton>
          <ConfirmButton onClick={ handleSubmit } disabled={ isFetching }>
            Deactivate Workspace
          </ConfirmButton>
        </ButtonsContainer>
      </ModalContent>
    </ModalWrapper>
  );
};

export default DeactivateConfirmationModal;
