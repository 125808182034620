import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 37px;
`;
Wrapper.displayName = 'Wrapper';

export const IconWrapper = styled.button`
  background-color: #f7f9fb;
  border: solid 1px #e6e9ed;
  height: 37px;
  width: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
IconWrapper.displayName = 'IconWrapper';
