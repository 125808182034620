/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContextMenuTrigger } from 'react-contextmenu';
import get from 'lodash/get';
import CustomReactTable from 'Common/Components/Contextualization/CustomReactTable/styled';
import ContextMenuWorkspaceMassiveUpdate from 'Screens/Contextualization/Workspaces/components/ContextMenuWorkspaceMassiveUpdate';
import { setOrderByWorkspaces } from 'Screens/Contextualization/Workspaces/actions/Actions';
import {
  selectIsFetching, selectPermissions, selectShowWsDetail, selectWorkspacesOrdered,
  selectWorkspacesSelected
} from 'store/Workspace/selectors';
import { TableWrapper } from './styled';
import getColumns from './Columns';
import { FormattedMessage } from 'react-intl';
import WorkspaceDetail from '../WorkspaceDetail';
import { selectFields } from 'store/Preferences/workspace/selectors';
import { selectIsLoggedIn } from 'store/Sesion/selectors';

const Table = ({ setShowConfirmation }) => {
  const dispatch = useDispatch();
  const workspacesSelected = useSelector(selectWorkspacesSelected);
  const workspaceList = useSelector(selectWorkspacesOrdered);
  const isFetching = useSelector(selectIsFetching);
  const showDetail = useSelector(selectShowWsDetail);
  const fields = useSelector(selectFields);
  const columns = getColumns(isFetching, setShowConfirmation, fields);
  const permissions = useSelector(selectPermissions);
  const canUpdate = get(permissions, 'update.allowed', true);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const getSorting = (sorted) => {
    const vulnsSortIndex = sorted.findIndex((s) => s.id === 'vulnerabilities');

    if (vulnsSortIndex >= 0) {
      const desc = sorted[vulnsSortIndex].desc;
      const sortTotalCount = { id: 'vulnerability_total_count', desc };

      const newSorting = [...sorted];
      newSorting.splice(vulnsSortIndex, 1, sortTotalCount);
      return newSorting;
    }
    return sorted;
  };

  const getTrProps = (rowInfo) => {
    const selected = workspacesSelected.filter((x) => x.id === rowInfo.original.id).length > 0;

    return {
      style: {
        background: selected ? '#edf2f7' : 'transparent'
      }
    };
  };

  const fetchData = (state) => {
    const { sorted } = state;
    if (!isFetching && isLoggedIn) {
      const sorting = getSorting(sorted);
      dispatch(setOrderByWorkspaces(sorting));
    }
  };

  const mockData = [...Array(13).keys()];

  return (
    <TableWrapper>
      <ContextMenuTrigger id="massive_update_workspace_table" holdToDisplay={ 1000000 }>
        <CustomReactTable
        // eslint-disable-next-line no-negated-condition
          data={ isFetching ? mockData : workspaceList }
          columns={ columns }
          minRows={ 0 }
          manual
          style={ { height: '97%', boxShadow: '-8px 14px 20px 0px rgba(0, 0, 0, 0.03)' } }
          onFetchData={ fetchData }
          showPagination={ false }
          getTrProps={ (_, rowInfo) => getTrProps(rowInfo) }
          noDataText={ <FormattedMessage id="app.nodata.context" /> }
        />
      </ContextMenuTrigger>
      <ContextMenuWorkspaceMassiveUpdate trigger="massive_update_workspace_table" onShow={ () => { } } />
      { showDetail && <WorkspaceDetail /> }
      { canUpdate
        ? <ContextMenuWorkspaceMassiveUpdate trigger="massive_update_workspace_table" onShow={ () => { } } />
        : null }
    </TableWrapper>
  );
};

export default Table;
