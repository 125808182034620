import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteAssetsSelected, hideHostModalDelete
} from 'store/Host/actions';
import ModalConfirmation from 'Common/Components/ModalConfirmation';
import { selectHostCount, selectHostsSelected, selectIsFetching, selectSelectAllHosts, selectShowDeleteConfirmation } from 'store/Host/selectors';

const DeleteConfirmationModal = () => {
  const dispatch = useDispatch();
  const hostsSelected = useSelector(selectHostsSelected);
  const showDeleteConfirmation = useSelector(selectShowDeleteConfirmation);
  const isFetching = useSelector(selectIsFetching);
  const hostsCount = useSelector(selectHostCount);
  const selectAll = useSelector(selectSelectAllHosts);
  const count = selectAll ? hostsCount : hostsSelected.length;

  return (
    <ModalConfirmation
      show={ showDeleteConfirmation }
      handleSubmit={ () => dispatch(deleteAssetsSelected()) }
      handleClose={ () => dispatch(hideHostModalDelete()) }
      entity="asset"
      count={ count }
      loading={ isFetching }
    />
  );
};

export default DeleteConfirmationModal;
