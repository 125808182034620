/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import 'react-table-6/react-table.css';
import {
  IoMdCheckmark as Check,
  IoMdClose as Remove
} from 'react-icons/io';
import get from 'lodash/get';
import FlagIcon from 'Common/Components/FlagIcon';
import DateFormatter from 'Common/Components/DateFormatter';
import Skeleton from 'Common/Components/Skeleton';
import {
  Impact, GenericCell, Name, CheckColum, CheckHeader, Confirm, Severity, Expander, SeverityHeader, CVE
} from './index';
import { CheckHeaderVulnAsset } from 'Screens/Contextualization/Host/components/HostVulnerabilities/VulnsTable/Table/CheckHeaderVulnAsset /CheckHeaderVulnAsset ';
import { CheckColumVulnAsset } from 'Screens/Contextualization/Host/components/HostVulnerabilities/VulnsTable/Table/CheckColumVulnAsset/CheckColumVulnAsset';
import NameCellVulnAsset from 'Screens/Contextualization/Host/components/HostVulnerabilities/VulnsTable/Table/NameCellVulnAsset ';

export default function getColumns (isPivot, customFields, showVulnDetail, currentBreakpoint, group_by, fields, isFetching, isFetchingExpandedData, hasGroupedData, vulnsAssetTable) {
  const extendFieldName = (showVulnDetail && currentBreakpoint === 'second');
  const showSkeleton = isFetching || (isFetchingExpandedData && !isPivot && !hasGroupedData);
  const columns = [
    {
      headerStyle: { display: 'none' },
      id: 'expander',
      show: !!(group_by && isPivot),
      sortable: false,
      filterable: false,
      maxWidth: 50,
      minWidth: 50,
      expander: true,
      custom_order: -1,
      style: { display: isFetching ? 'none' : '' },
      Expander: ({ isExpanded, original }) => <Expander isFetching={ isFetching } isExpanded={ !!isExpanded } original={ original } group_by={ group_by } />
    }, {
      Header: () => (vulnsAssetTable ? <CheckHeaderVulnAsset /> : ((!group_by || !isPivot) && <CheckHeader />)),
      id: 'selected',
      accessor: 'Selected',
      show: true,
      sortable: false,
      resizable: false,
      maxWidth: 55,
      minWidth: 55,
      headerStyle: { overflow: 'visible' },
      style: { display: 'flex' },
      Cell: (rowInfo) => vulnsAssetTable ? <CheckColumVulnAsset vuln={ rowInfo.original } showSkeleton={ showSkeleton } /> : ((!group_by || !isPivot) && !isFetching && (!isFetchingExpandedData || hasGroupedData) && <CheckColum vuln={ rowInfo.original } />)
    }, {
      Header: () => <SeverityHeader />,
      headerClassName: 'hideSortIcon',
      id: 'severity',
      accessor: 'severity',
      show: fields.severity.visible && (!group_by || group_by !== 'severity'),
      sortable: !group_by,
      resizable: false,
      maxWidth: 55,
      minWidth: 55,
      Cell: (rowInfo) => (showSkeleton ? <Skeleton /> : (!isPivot || !group_by) && <Severity vuln={ rowInfo.original } />),
      custom_order: fields.severity.order
    }, {
      Header: () => <FlagIcon title="Confirmed / Not Confirmed" theme="allVulns" />,
      headerClassName: 'hideSortIcon',
      id: 'confirmed',
      accessor: 'confirmed',
      show: fields.confirmed.visible,
      sortable: !group_by,
      resizable: false,
      maxWidth: 45,
      minWidth: 45,
      Cell: (rowInfo) => (showSkeleton ? <Skeleton /> : (!isPivot || !group_by) && <Confirm vuln={ rowInfo.original } onAsset={ vulnsAssetTable } />),
      custom_order: fields.confirmed.order
    }, {
      Header: () => <div children="NAME" />,
      id: 'name',
      accessor: 'name',
      show: fields.name.visible,
      sortable: !group_by,
      resizable: !group_by,
      maxWidth: group_by ? fields.name.defaultMin : fields.name.maxWidth,
      minWidth: fields.name.minWidth,
      getProps: (state, rowInfo) => {
        const index = get(rowInfo, 'index', 0);
        const selected = get(state, 'selected', 0);
        if (index !== selected) return ({ className: `column_name min-wd-${fields.name.defaultMin} ${extendFieldName ? '' : `max-wd-${fields.name.maxWidth}`}` });
        return ({ className: `min-wd-${fields.name.defaultMin} ${extendFieldName ? '' : `max-wd-${fields.name.maxWidth}`}` });
      },
      custom_order: fields.name.order,
      Cell: (rowInfo) => vulnsAssetTable ? <NameCellVulnAsset rowInfo={ rowInfo.original } showSkeleton={ showSkeleton } /> : <Name showSkeleton={ showSkeleton } groupBy={ group_by } isPivot={ isPivot } vuln={ rowInfo.original } />
    }, {
      Header: () => <div children="SERVICE" />,
      id: 'service__name',
      show: fields.service__name.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: !group_by,
      resizable: !group_by,
      maxWidth: group_by ? fields.service__name.defaultMin : fields.service__name.maxWidth,
      minWidth: fields.service__name.minWidth,
      Cell: (rowInfo) => (
        showSkeleton ? <Skeleton /> : rowInfo.original.service ? `(${rowInfo.original.service.ports}/${rowInfo.original.service.protocol}) ${rowInfo.original.service.name}` : ''
      ),
      getProps: () => ({ className: `min-wd-${fields.service__name.defaultMin}` }),
      custom_order: fields.service__name.order
    }, {
      Header: () => <div children="ASSET" />,
      id: 'target',
      accessor: 'asset',
      show: !vulnsAssetTable && fields.asset.visible && !(showVulnDetail && currentBreakpoint === 'second') && group_by !== 'cve_instances__name',
      sortable: !group_by,
      resizable: !group_by,
      maxWidth: vulnsAssetTable ? 0 : group_by ? fields.asset.defaultMin : fields.asset.maxWidth,
      minWidth: vulnsAssetTable ? 0 : fields.asset.minWidth,
      // eslint-disable-next-line no-negated-condition
      getProps: (state, rowInfo) => !vulnsAssetTable ? (rowInfo.index !== state.selected ? { className: `column_target min-wd-${fields.asset.defaultMin}` } : { className: `min-wd-${fields.asset.defaultMin}` }) : '',
      custom_order: vulnsAssetTable ? -1 : fields.asset.order,
      Cell: (rowInfo) => <GenericCell showSkeleton={ showSkeleton } rowInfo={ rowInfo } attrib="target" />
    }, {
      Header: () => <div children="CVSS" />,
      id: 'cvss',
      accessor: 'cvss',
      show: fields.cvss.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: false,
      resizable: !group_by,
      maxWidth: group_by ? fields.cvss.defaultMin : fields.cvss.maxWidth,
      minWidth: fields.cvss.minWidth,
      custom_order: fields.cvss.order,
      Cell: (rowInfo) => {
        if (!rowInfo.original.cvss3) return null;
        return <>{rowInfo.original.cvss3.base_score || rowInfo.original.cvss3.base_score}</>;
      },
      getProps: () => ({ className: `min-wd-${fields.cvss.defaultMin}` })
    }, {
      Header: () => <div children="CVE" />,
      id: 'cve',
      accessor: 'cve',
      show: fields.cve.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: false,
      resizable: !group_by,
      maxWidth: group_by ? fields.cve.defaultMin : fields.cve.maxWidth,
      minWidth: fields.cve.minWidth,
      Cell: (rowInfo) => <CVE showSkeleton={ showSkeleton } vuln={ rowInfo.original } isFetching={ isFetching } />,
      getProps: () => ({ className: `min-wd-${fields.cve.defaultMin}` }),
      custom_order: fields.cve.order
    }, {
      Header: () => <div children="CWE" />,
      id: 'cwe',
      accessor: 'cwe',
      show: fields.cwe.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: false,
      resizable: !group_by,
      maxWidth: group_by ? fields.cwe.defaultMin : fields.cwe.maxWidth,
      minWidth: fields.cwe.minWidth,
      custom_order: fields.cwe.order,
      Cell: (rowInfo) => {
        if (!rowInfo.original.cwe) return null;
        return <>{rowInfo.original.cwe.map(el => (<>{el} </>))}</>;
      },
      getProps: () => ({ className: `min-wd-${fields.cwe.defaultMin}` })
    }, {
      Header: () => <div children="ID" />,
      id: 'id',
      accessor: '_id',
      show: fields.id.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: !group_by,
      resizable: !group_by,
      maxWidth: group_by ? fields.id.defaultMin : fields.id.maxWidth,
      minWidth: fields.id.minWidth,
      getProps: () => ({ className: `min-wd-${fields.id.defaultMin}` }),
      custom_order: fields.id.order,
      Cell: (rowInfo) => <GenericCell showSkeleton={ showSkeleton } rowInfo={ rowInfo } attrib="obj_id" />
    }, {
      Header: () => <div children="ASSET OS" />,
      id: 'host__os',
      accessor: 'host_os',
      show: fields.host__os.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: !group_by,
      resizable: !group_by,
      maxWidth: group_by ? fields.host__os.defaultMin : fields.host__os.maxWidth,
      minWidth: fields.host__os.minWidth,
      getProps: () => ({ className: `min-wd-${fields.host__os.defaultMin}` }),
      custom_order: fields.host__os.order,
      Cell: (rowInfo) => <GenericCell showSkeleton={ showSkeleton } rowInfo={ rowInfo } attrib="host_os" />
    }, {
      Header: () => <div children="EASE OF RESOLUTION" />,
      id: 'easeofresolution',
      accessor: 'easeofresolution',
      show: fields.easeofresolution.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: !group_by,
      resizable: !group_by,
      maxWidth: group_by ? fields.easeofresolution.defaultMin : fields.easeofresolution.maxWidth,
      minWidth: fields.easeofresolution.minWidth,
      getProps: () => ({ className: `min-wd-${fields.easeofresolution.defaultMin}` }),
      custom_order: fields.easeofresolution.order,
      Cell: (rowInfo) => <GenericCell showSkeleton={ showSkeleton } rowInfo={ rowInfo } attrib="easeofresolution" capitalize />
    }, {
      Header: () => <div children="WEBSITE" />,
      id: 'website',
      accessor: 'website',
      show: fields.website.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: false,
      resizable: !group_by,
      maxWidth: group_by ? fields.website.defaultMin : fields.website.maxWidth,
      minWidth: fields.website.minWidth,
      getProps: () => ({ className: `min-wd-${fields.website.defaultMin}` }),
      custom_order: fields.website.order,
      Cell: (rowInfo) => <GenericCell showSkeleton={ showSkeleton } rowInfo={ rowInfo } attrib="website" />
    }, {
      Header: () => <div children="PATH" />,
      id: 'path',
      accessor: 'path',
      show: fields.path.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: false,
      resizable: !group_by,
      maxWidth: group_by ? fields.path.defaultMin : fields.path.maxWidth,
      minWidth: fields.path.minWidth,
      getProps: () => ({ className: `min-wd-${fields.path.defaultMin}` }),
      custom_order: fields.path.order,
      Cell: (rowInfo) => <GenericCell showSkeleton={ showSkeleton } rowInfo={ rowInfo } attrib="path" />
    }, {
      Header: () => <div children="STATUS CODE" />,
      id: 'status_code',
      accessor: 'status_code',
      show: fields.status_code.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: !group_by,
      resizable: !group_by,
      maxWidth: group_by ? fields.status_code.defaultMin : fields.status_code.maxWidth,
      minWidth: fields.status_code.minWidth,
      getProps: () => ({ className: `min-wd-${fields.status_code.defaultMin}` }),
      custom_order: fields.status_code.order,
      Cell: (rowInfo) => <GenericCell showSkeleton={ showSkeleton } rowInfo={ rowInfo } attrib="status_code" />
    }, {
      Header: () => <div children="HOSTNAMES" />,
      id: 'hostnames',
      accessor: 'hostnames',
      show: fields.hostnames.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: false,
      resizable: !group_by,
      maxWidth: group_by ? fields.hostnames.defaultMin : fields.hostnames.maxWidth,
      minWidth: fields.hostnames.minWidth,
      Cell: (rowInfo) => {
        const hostnames = get(rowInfo, 'original.hostnames', []);
        return (
          showSkeleton
            ? <Skeleton />
            : <div className="three-dots">{hostnames.join(', ')}</div>
        );
      },
      getProps: () => ({ className: `min-wd-${fields.hostnames.defaultMin}` }),
      custom_order: fields.hostnames.order
    }, {
      Header: () => <div children="IMPACT" />,
      id: 'impact',
      show: fields.impact.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: false,
      resizable: !group_by,
      maxWidth: group_by ? fields.impact.defaultMin : fields.impact.maxWidth,
      minWidth: fields.impact.minWidth,
      Cell: (rowInfo) => <Impact showSkeleton={ showSkeleton } rowInfo={ rowInfo } />,
      getProps: () => ({ className: `min-wd-${fields.impact.defaultMin}` }),
      custom_order: fields.impact.order
    }, {
      Header: () => <div children="METHOD" />,
      id: 'method',
      accessor: 'method',
      show: fields.method.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: false,
      resizable: !group_by,
      maxWidth: group_by ? fields.method.defaultMin : fields.method.maxWidth,
      minWidth: fields.method.minWidth,
      getProps: () => ({ className: `min-wd-${fields.method.defaultMin}` }),
      custom_order: fields.method.order,
      Cell: (rowInfo) => <GenericCell showSkeleton={ showSkeleton } rowInfo={ rowInfo } attrib="method" />
    }, {
      Header: () => <div children="QUERY" />,
      id: 'query',
      accessor: 'query',
      show: fields.query.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: false,
      resizable: !group_by,
      maxWidth: group_by ? fields.query.defaultMin : fields.query.maxWidth,
      minWidth: fields.query.minWidth,
      getProps: () => ({ className: `min-wd-${fields.query.defaultMin}` }),
      custom_order: fields.query.order,
      Cell: (rowInfo) => <GenericCell showSkeleton={ showSkeleton } rowInfo={ rowInfo } attrib="query" />
    }, {
      Header: () => <div children="EVIDENCE" />,
      id: 'evidence',
      accessor: 'evidence',
      show: fields.evidence?.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: false,
      resizable: !group_by,
      maxWidth: group_by ? fields.evidence?.defaultMin : fields.evidence?.maxWidth,
      minWidth: fields.evidence?.minWidth,
      getProps: () => ({ className: `min-wd-${fields.evidence?.defaultMin}` }),
      custom_order: fields.evidence?.order,
      Cell: (rowInfo) => <GenericCell showSkeleton={ showSkeleton } rowInfo={ rowInfo } attrib="attachments_count" />
    }, {
      Header: () => <div children="WEB" />,
      id: 'type',
      accessor: 'type',
      show: fields.web.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: !group_by,
      resizable: !group_by,
      maxWidth: group_by ? fields.web.defaultMin : fields.web.maxWidth,
      minWidth: fields.web.minWidth,
      Cell: (rowInfo) => {
        if (showSkeleton) return <Skeleton />;
        if (group_by) return null;
        if (rowInfo.original.type === 'VulnerabilityWeb') return <Check />;
        return <Remove />;
      },
      getProps: () => ({ className: `min-wd-${fields.web.defaultMin}` }),
      custom_order: fields.web.order
    }, {
      Header: () => <div children="TOOL" />,
      id: 'tool',
      accessor: 'metadata.tool',
      show: fields.tool.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: !group_by,
      resizable: !group_by,
      maxWidth: group_by ? fields.tool.defaultMin : fields.tool.maxWidth,
      minWidth: fields.tool.minWidth,
      getProps: () => ({ className: `min-wd-${fields.tool.defaultMin}` }),
      custom_order: fields.tool.order,
      Cell: (rowInfo) => <GenericCell showSkeleton={ showSkeleton } rowInfo={ rowInfo } attrib="tool" />
    }, {
      Header: () => <div children="EXTERNAL ID" />,
      id: 'external_id',
      accessor: 'external_id',
      show: fields.external_id.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: !group_by,
      resizable: !group_by,
      maxWidth: group_by ? fields.external_id.defaultMin : fields.external_id.maxWidth,
      minWidth: fields.external_id.minWidth,
      getProps: () => ({ className: `min-wd-${fields.external_id.defaultMin}` }),
      custom_order: fields.external_id.order,
      Cell: (rowInfo) => <GenericCell showSkeleton={ showSkeleton } rowInfo={ rowInfo } attrib="external_id" />
    }, {
      Header: () => <div children="Created" />,
      id: 'create_date',
      show: fields.create_date.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: !group_by,
      resizable: !group_by,
      maxWidth: group_by ? fields.create_date.defaultMin : fields.create_date.maxWidth,
      minWidth: fields.create_date.minWidth,
      Cell: (rowInfo) => (showSkeleton ? <Skeleton /> : rowInfo.original.date ? <DateFormatter date={ rowInfo.original.date } /> : null),
      getProps: () => ({ className: `min-wd-${fields.create_date.defaultMin}` }),
      custom_order: fields.create_date.order
    }, {
      Header: () => <div children="Updated" />,
      id: 'update_date',
      show: fields.update_date.visible && !(showVulnDetail && currentBreakpoint === 'second'),
      sortable: !group_by,
      resizable: !group_by,
      maxWidth: group_by ? fields.update_date.defaultMin : fields.update_date.maxWidth,
      minWidth: fields.update_date.minWidth,
      Cell: (rowInfo) => {
        const updateTime = get(rowInfo, 'original.metadata.update_time', null);
        if (showSkeleton) return <Skeleton />;
        if (!updateTime) return null;
        return <DateFormatter date={ updateTime } />;
      },
      getProps: () => ({ className: `min-wd-${fields.update_date.defaultMin}` }),
      custom_order: fields.update_date.order
    }, {
      Header: () => <div children="STATUS" className={ `min-wd-${fields.status.defaultMin}` } />,
      id: 'status',
      accessor: 'status',
      show: fields.status.visible && !(showVulnDetail && currentBreakpoint === 'second') && (!group_by || group_by !== 'status'),
      sortable: !group_by,
      resizable: !group_by,
      maxWidth: group_by ? fields.status.defaultMin : fields.status.maxWidth,
      minWidth: fields.status.minWidth,
      Cell: (rowInfo) => <GenericCell showSkeleton={ showSkeleton } rowInfo={ rowInfo } attrib="status" capitalize />,
      getProps: (state, rowInfo) => (rowInfo.original.status === 'open' ? { 'className': `open min-wd-${fields.status.defaultMin}`, 'column-type': 'status' } : { 'className': `${rowInfo.original.status} min-wd-${fields.status.defaultMin}`, 'column-type': 'status' }),
      custom_order: fields.status.order
    }
  ];

  if (customFields) {
    customFields.forEach((cf) => {
      if (fields[cf.field_display_name] && fields[cf.field_display_name].visible && !(showVulnDetail && currentBreakpoint === 'second')) {
        columns.push({
          Header: () => <div children={ cf.field_display_name.toUpperCase() } />,
          id: cf.field_name,
          show: true,
          sortable: false,
          resizable: false,
          maxWidth: 200,
          minWidth: 200,
          custom_order: fields[cf.field_display_name].order,
          Cell: (rowInfo) => (
            showSkeleton ? <Skeleton /> : <p>{rowInfo.original.custom_fields && !group_by ? rowInfo.original.custom_fields[cf.field_name] : ''}</p>
          )
        });
      }
    });
  }

  const ordered_columns = columns.filter((row, index) => row.show === true || index === 0).sort((a, b) => (a.custom_order < b.custom_order ? -1 : 1));
  return ordered_columns;
}
