import * as types from './types';
import uniq from 'lodash/uniq';

export const initialState = {
  isFetching: false,
  error: false,
  errorMessage: '',
  list: [],
  showServiceDetail: false,
  serviceDetail: undefined,
  servicesSelected: [],
  lastSelected: -1,
  showDeleteConfirmation: false,
  order_by: 'name',
  order_by_dir: 'desc'
};

function services (state = initialState, action) {
  switch (action.type) {
    case types.SERVICES_FETCHING: {
      return {
        ...state,
        isFetching: true
      };
    }
    case types.SERVICES_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.errorMessage
      };
    }
    case types.SERVICES_CLEAR_ERROR: {
      return {
        ...state,
        error: false,
        errorMessage: ''
      };
    }
    case types.SERVICES_GET_SUCCESS: {
      return {
        ...state,
        list: action.data,
        count: action.count,
        isFetching: false
      };
    }
    case types.SERVICE_SELECTED: {
      return {
        ...state,
        servicesSelected: uniq([...state.servicesSelected, ...action.payload]),
        serviceSelectedForWorking: uniq([...state.servicesSelected, ...action.payload])
      };
    }
    case types.SERVICE_UNSELECTED: {
      const filterByID = (array, id) => array.filter((el) => el._id !== id);
      const newServicesSelected = action.payload.reduce((servicesSelected, serviceToDelete) => filterByID(servicesSelected, serviceToDelete._id), state.servicesSelected);

      return {
        ...state,
        servicesSelected: newServicesSelected
      };
    }
    case types.NEW_PIVOT: {
      return {
        ...state,
        lastSelected: action.payload
      };
    }
    case types.SELECT_ALL_SERVICE: {
      return {
        ...state,
        servicesSelected: [...action.servicesList]
      };
    }
    case types.UNSELECT_ALL_SERVICE: {
      return {
        ...state,
        servicesSelected: []
      };
    }
    case types.SERVICES_SHOW_DETAIL: {
      return {
        ...state,
        serviceDetail: action.service,
        showServiceDetail: true
      };
    }
    case types.SERVICES_HIDE_DETAIL: {
      return {
        ...state,
        serviceDetail: undefined,
        showServiceDetail: false
      };
    }
    case types.SERVICES_SHOW_MODAL_DELETE: {
      return {
        ...state,
        showDeleteConfirmation: true
      };
    }
    case types.SERVICES_HIDE_MODAL_DELETE: {
      return {
        ...state,
        showDeleteConfirmation: false
      };
    }
    case types.SERVICES_DELETE_SUCCESS: {
      return {
        ...state,
        servicesSelected: [],
        isFetching: false
      };
    }
    case types.SERVICES_DELETE_FAIL: {
      return {
        ...state,
        isFetching: false
      };
    }
    case types.SERVICES_UPDATE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        list: action.data,
        serviceCount: action.serviceCount
      };
    }
    case types.SERVICES_RESET_EDIT: {
      return {
        ...state,
        serviceDetail: state.initialState
      };
    }
    case types.SERVICES_RESET_STATE: {
      return {
        serviceSelectedForWorking: state.serviceSelectedForWorking,
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
}

export default services;
