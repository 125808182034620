import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setPage } from 'store/Manage/filterActions';
import { downloadVulns } from 'store/Manage/actions';
import { selectPage, selectRowsPerPage, selectGroupByField } from 'store/Filters/selectors';
import {
  selectVulnsSelected,
  selectShowDetail,
  selectIsExportingCSV,
  selectVulnsCount,
  selectShowDeleteConfirmation
} from 'store/Manage/selectors';
import { Wrapper, LeftGroup, RightGroup, Separator, Group } from 'Common/Components/Contextualization/CustomActionBar/styled';
import Pagination from 'Common/Components/Pagination';
import CreateActions from 'Screens/Contextualization/Manage/components/CreateActions';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import GroupFieldSelector from '../GroupFieldSelector';
import FilterSideBarIcon from '../FilterSidebarIcon';
import DuplicateButton from '../DuplicateButton';
import TagButton from '../TagButton';
import RefreshButton from '../RefreshButton';
import AddRemoveColumnsButton from '../AddRemoveColumnsButton';
import { Download, DownloadButton } from '../../styled';
import CopyLink from '../CopyLink';
import CopyApiLink from '../CopyApiLink';
import Severity from '../Severity';
import Status from '../Status';
import AddNewEvidence from '../AddNewEvidence';
import SaveAsTemplate from '../SaveAsTemplate';
import ApplyTemplate from '../ApplyTemplate';
import FilterBy from '../FilterBy';
import { selectManageFields } from 'store/Preferences/manage/selectors';

const ActionBar = ({ setFF, entity }) => {
  const dispatch = useDispatch();
  const vulnsSelected = useSelector(selectVulnsSelected);
  const vulnsCount = useSelector(selectVulnsCount);
  const showVulnDetail = useSelector(selectShowDetail);
  const page = useSelector((state) => selectPage('vulns', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('vulns', state));
  const isGrouping = useSelector((state) => selectGroupByField('vulns', state));
  const hasVulns = vulnsCount > 0;
  const onPrev = () => dispatch(setPage(page - 1));
  const onNext = () => dispatch(setPage(page + 1));
  const isExportingCSV = useSelector(selectIsExportingCSV);
  const fields = useSelector(selectManageFields);
  const showDeleteConfirmation = useSelector(selectShowDeleteConfirmation);

  return (
    <Wrapper data-tour="Toolbar Vulnerabilities">
      { showDeleteConfirmation ? <DeleteConfirmationModal /> : null }
      <LeftGroup>
        <CreateActions />
        <Separator />
        <Group>
          <EditButton hasVulns={ hasVulns } />
          <TagButton setFF={ setFF } hasItems={ hasVulns } />
          <CopyLink hasVulns={ hasVulns } />
          <CopyApiLink hasVulns={ hasVulns } />
          <Severity hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } entity={ entity } />
          <Status hasVulns={ hasVulns } groupBy={ isGrouping } vulnsSelected={ vulnsSelected } entity={ entity } />
          <AddNewEvidence hasVulns={ hasVulns } />
          <SaveAsTemplate hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } entity={ entity } />
          <ApplyTemplate hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } />
          <FilterBy hasVulns={ hasVulns } />
          <DeleteButton hasVulns={ hasVulns } />
          <DuplicateButton setFF={ setFF } />
        </Group>
      </LeftGroup>

      <RightGroup>

        { ((hasVulns) && (vulnsSelected.length === 0 && !showVulnDetail)) && <GroupFieldSelector /> }

        { <Pagination offset={ (hasVulns && !isGrouping) ? page : null } limit={ rowsPerPage } total={ vulnsCount } onPrev={ onPrev } onNext={ onNext } />}

        { hasVulns && <Separator /> }

        <Group>
          { hasVulns && (
             <DownloadButton disabled={ isExportingCSV } title={ isExportingCSV ? 'Exporting CSV' : 'Download Filtered Workspace' } onClick={ () => dispatch(downloadVulns()) }>
              <Download />
             </DownloadButton>
          )}
          <RefreshButton />
          <FilterSideBarIcon entity="vulns" />
          <AddRemoveColumnsButton fields={ fields } />
        </Group>
      </RightGroup>
    </Wrapper>
  );
};

export default ActionBar;
