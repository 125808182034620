import styled from 'styled-components';
import colors from 'Styles/colors';
import { FaChevronDown as ChevronDown } from 'react-icons/fa';
import { ReactComponent as CloseSVG } from 'Images/icon-close-without-background.svg';

export const Wrapper = styled.div`
  height: 37px;
  width: 168px;
  align-self: center;
  margin-right: 10px;
  &:hover{
    background-color: ${colors.grey25};
    border-radius: 5px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Toggle = styled.div`
  height: 37px;
  font-size: 11.5px;
  color: ${colors.dark2};
  width: 168px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 13px 0 15px;
  cursor: pointer;
  border-radius: 2px;
  box-shadow: 0 10px 15px 0 rgba(190, 200, 210, 0.24), inset 0 -1px 30px 0 rgba(99, 117, 141, 0.14);
  border: solid 3px rgba(190, 200, 210, 0.29);
  background-color: ${colors.iceBlue};
  &:after {
    content: none;
  }
`;
Toggle.displayName = 'Toggle';

export const Menu = styled.div`
  background:#fff;
  width: 264px !important;
  height: fit-content;
  padding-top: 8px;
  padding-bottom: 14px;
  position: absolute;
  z-index: 99;
  border-radius: 2px;
  box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px #cdd5dd;

  a {
    text-decoration: none;
  }
`;
Menu.displayName = 'Menu';

export const Items = styled.div`
  max-height: 175px;
  overflow-y: scroll;
`;
Items.displayName = 'Items';

export const Item = styled.div`
  cursor: pointer;
  padding-left: 21px;
  padding-right: 17px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  &:hover {
    background-color: ${colors.iceBlue} !important;
  }

  &:active {
    color: ${colors.dark2};
  }
`;
Item.displayName = 'Item';

export const NoItems = styled.div`
  padding-left: 13px;
  padding-right: 13px;
  height: 43px;
  display: flex;
  align-items: center;
  font-size: 13.5px;
  font-weight: 300;
  font-stretch: normal;
  max-width: 235px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
NoItems.displayName = 'NoItems';

export const AssetSelectedIp = styled.div`
  color: ${colors.dark2};
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
AssetSelectedIp.displayName = 'AssetSelectedIp';

export const ArrowDown = styled(ChevronDown)`
  width: 10px;
  color: ${colors.grey5};
`;
ChevronDown.displayName = 'ChevronDown';

export const AssetOptionIp = styled.span`
  font-size: 13.5px;
  font-weight: 400;
  font-stretch: normal;
  color: ${colors.dark2};
  max-width: 235px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
AssetOptionIp.displayName = 'AssetOptionIp';

export const ActionsContainer = styled.div`
  margin: 21px 7px 0 21px;
  padding-left: 13px;
  padding-right: 13px;
`;
ActionsContainer.displayName = 'ActionsContainer';

export const ViewAll = styled.span`
  font-size: 12.5px;
  font-weight: 300;
  font-stretch: normal;
  color: ${colors.blueCerulean};
  float: left;
  cursor: pointer;
  position: absolute;
  left: 21px;
`;
ViewAll.displayName = 'ViewAll';

export const CreateNew = styled.span`
  font-size: 12.5px;
  font-weight: 300;
  font-stretch: normal;
  color: ${colors.blueCerulean};
  float: right;
  cursor: pointer;
`;
CreateNew.displayName = 'CreateNew';

export const AssetVulns = styled.span`
  color: ${colors.darkBlueGrey};
  font-size: 12.5px;
  font-weight: 300;
  font-stretch: normal;
  padding-top: 1px;
`;
AssetVulns.displayName = 'AssetVulns';

export const CloseIconWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid #e5e8ec;
  background-color: ${colors.iceBlue};
  margin-right: 8px;
`;
CloseIconWrapper.displayName = 'CloseIconWrapper';

export const CloseIcon = styled(CloseSVG)`
  position: absolute;
  left: 2.5px;
  top: 3px;
  margin: auto;
  width: 15px;
  height: 15px;
  cursor: pointer;
  & g {
    & path{
      fill: ${colors.grey19};
    }  
  }
`;
CloseIcon.displayName = 'CloseIcon';

export const Icons = styled.div`
  display: flex;
  align-items: center;
`;
Icons.displayName = 'Icons';
