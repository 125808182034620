import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  margin-right: 10px;
`;
Wrapper.displayName = 'Wrapper';

export const Count = styled.div`
  padding: 7px 9px;
  height: 37px;
  box-shadow: inset 0 0 9px 0 rgba(28, 34, 67, 0.07);
  border-top: solid 1px #e6e9ed;
  border-left: solid 1px #e6e9ed;
  border-bottom: solid 1px #e6e9ed;
  background-color: #fdfeff;
  font-size: 13.5px;
  font-weight: 500;
  text-align: center;
  color: ${colors.grey19};
  max-width: 142px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
Count.displayName = 'Count';
